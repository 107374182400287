<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 support section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li class="fucus"><a @click="$router.push({name:'Faq'})"><span>자주묻는 질문</span></a></li>
            <li><a @click="$router.push({name:'Notice'})"><span>공지사항</span></a></li>
            <li><a @click="$router.push({name:'InquiryList'})"><span>문의사항</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2  FAQ ======= -->
    <section class="support section2">
      <div class="container">
        <div class="heading-tit">
          <h2>자주묻는 질문</h2>
          <p>결제나라의 자주묻는 질문들을 모아두었습니다. </p>
        </div>

        <div class="support-content">
          <!-- 탭 메뉴 -->
          <ul class="tab">
            <li class="tab_link on"><a href="#">전체</a></li>
            <li class="tab_link"><a href="#">가입</a></li>
            <li class="tab_link"><a href="#">결제</a></li>
            <li class="tab_link"><a href="#">수수료</a></li>
            <li class="tab_link"><a href="#">정산</a></li>
            <li class="tab_link"><a href="#">취소</a></li>
            <li class="tab_link"><a href="#">기타</a></li>
          </ul>
          <div class="content-box">
            <!-- 전체 -->
            <ul class="faq faq_0">
              <li class="">
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>가입</span>가맹점 계약 절차는 어떻게 되나요?떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
              <li>
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>수수료</span> 가맹점 계약 절차는 어떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
              <li>
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>가입</span> 가맹점 계약 절차는 어떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
            </ul>
            <!-- 가입 -->
            <ul class="faq faq_1">
              <li class="">
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>가입</span>가맹점 계약 절차는 어떻게 되나요?떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
              <li>
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>가입</span> 가맹점 계약 절차는 어떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
            </ul>
            <!-- 결제 -->
            <ul class="faq faq_2">
              <li class="">
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>결제</span>가맹점 계약 절차는 어떻게 되나요?떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
              <li>
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>결제</span> 가맹점 계약 절차는 어떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
            </ul>
            <!-- 수수료 -->
            <ul class="faq faq_3">
              <li class="">
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>수수료</span>가맹점 계약 절차는 어떻게 되나요?떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
              <li>
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>수수료</span> 가맹점 계약 절차는 어떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
            </ul>
            <!-- 정산 -->
            <ul class="faq faq_4">
              <li class="">
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>정산</span>가맹점 계약 절차는 어떻게 되나요?떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
            </ul>
            <!-- 취소 -->
            <ul class="faq faq_5">
              <li class="">
                <div class="question">
                  <p class="question-tit f-tran">
                    <b>Q.</b><span>취소</span>가맹점 계약 절차는 어떻게 되나요?떻게 되나요?
                  </p>
                  <img src="/image/icon_arrow.svg" class="btn-arrow" alt="열기">
                </div>
                <p class="answer f-tran">
                  결제나라 홈페이지 또는 결제나라 어플에서 회원 가입하실 수 있습니다.
                </p>
              </li>
            </ul>
            <!-- 기타 -->
          </div>
        </div>
      </div>

    </section><!-- End FAQ -->
  </main>
</template>

<script>
export default {
name: "Faq",
  path: '/support/faq',
}
</script>

<style scoped>

</style>

<template>
  <main class="sub-main service">
    <!-- ======= section1 function ======= -->
    <section class="section-80 service section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'ServiceQuick'})"><span>퀵비충전</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'ServiceMonthly'})"><span>월세결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceQr'})"><span>QR·카톡·수기결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceShop'})"><span>쇼핑몰 지원</span></a></li>
            <li><a @click="$router.push({name:'ServiceSales'})"><span>매출 알림 서비스</span></a></li>
          </ul>
        </div>
        <div class="function-cont">
          <div class="row">
            <div class="fun-tit">
              <h2><em>현금만 가능했던 비용을 신용카드로</em><br>월세 결제 서비스</h2>
              <hr>
              <p>현금으로만 결제하던 월세를 이제 신용카드 결제로 이용해보세요.<br>
                카드결제로 받았던 혜택까지 동일하게 모두 챙겨가실 수 있습니다.
              </p>
            </div>
            <div class="fun-img">
              <img src="/image/service_monthly.png" alt="월세결제 서비스">
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 keyword ======= -->
    <section class="section-80 service section2 section-keyword bg-pink">
      <h2 class="hide">퀵비·월세결제 장점</h2>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="pg sprites">국내 최저 수수료</i>
              <h4>국내 최저 수수료</h4>
              <p>
                퀵비 충전 서비스 이용료는 0%<small>(부가세별도)</small>로 업계 최저에 도전합니다.<br><small>(10만원 결제 시 0원 제한 후 다음날 충전)</small>
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="monthly sprites">임대인 동의NO</i>
              <h4>임대인 동의NO</h4>
              <p>
                세입자 명으로 임대인 계좌로 입금되어 별도의 동의가 필요없습니다.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="pay sprites">모든 신용카드 결제</i>
              <h4>모든 신용카드 결제</h4>
              <p>
                최초 카드 등록 후, 다음부터는 간편결제로 바로바로 결제가능합니다.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="regular sprites">정기결제 지원</i>
              <h4>정기결제 지원</h4>
              <p>
                정기 결제가 필요한 경우 별도의 심사 없이 매달 지정된 날짜에 결제가 이뤄집니다.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="card sprites">상시 무이자 할부 지원</i>
              <h4>상시 무이자 할부 지원</h4>
              <p>
                국내 카드사의 2개월 ~ 6개월 상시 무이자 할부를 지원합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section2 -->
    <!-- ======= section3 screen ======= -->
    <section class="section-80 service section3 quick-section">
      <div class="container">
        <h2 class="sub-tit">월세결제 화면</h2>
        <div class="row">
          <div class="screen-box">
            <span class="screen-tit">01. 월세 납입</span>
            <div class="web_sc">
              <ul>
                <li class="col-3"><img src="/image/screen_monthly_1.png" alt="입주건물정보입력"></li>
                <li class="col-3"><img src="/image/screen_monthly_2.png" alt="결제정보입력"></li>
                <li class="col-3"><img src="/image/screen_monthly_3.png" alt="신용카드선택"></li>
                <li class="col-3"><img src="/image/screen_monthly_4.png" alt="신청완료"></li>
              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_1 sw_quick_1">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_monthly_1.png" alt="입주건물정보입력">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_monthly_2.png" alt="결제정보입력">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_monthly_3.png" alt="신용카드선택">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_monthly_4.png" alt="신청완료 ">
                </div>

              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
          <div class="screen-box screen-box2">
            <span class="screen-tit">02. 퀵비 내역</span>
            <div class="web_sc">
              <ul>
                <li class="col-3"><img src="/image/screen_monthly_5.png" alt="월세결제내역전체보기"></li>
                <li class="col-3"><img src="/image/screen_monthly_6.png" alt="월예정납입금액"></li>
                <li class="col-3"><img src="/image/screen_monthly_7.png" alt="결제내역"></li>
              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_2 sw_quick_2">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_monthly_5.png" alt="월세결제내역전체보기">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_monthly_6.png" alt="월예정납입금액">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_monthly_7.png" alt="결제내역">
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>

        </div>
      </div>
    </section><!-- End section3 -->
  </main>
</template>

<script>
export default {
  name: "ServiceMonthly",
  path: '/service/monthly',
  mounted() {

    // 월세결제 화면
    new window.Swiper('.sw_quick_1', {
      loop: false,
      navigation: {
        nextEl: ".sw_quick_1 .swiper-button-next",
        prevEl: ".sw_quick_1 .swiper-button-prev",
      },
    });
    new window.Swiper('.sw_quick_2', {
      loop: false,
      navigation: {
        nextEl: ".sw_quick_2 .swiper-button-next",
        prevEl: ".sw_quick_2 .swiper-button-prev",
      },
    });
  }
}
</script>

<style scoped>

</style>

<template>
  <header class="header" :class="{'bg-pink-head': isPinkBg}">
    <div class="sticky-bar"></div>
    <div class="header-wrap" :class="{'mb-bg':mobileOn}">
      <div class="container pl-0 pr-0">
        <h1>
          <a @click="$router.push({name:'Main'})">페이허브 가맹점 솔루션 결제나라</a>
        </h1>
        <nav class="gnb">
          <ul>
            <li><a @click="$router.push({name:'About'})" :class="{focus:$route.path.startsWith('/about')}">결제나라</a></li>
            <li><a @click="$router.push({name:'ServiceQuick'})" :class="{focus:$route.path.startsWith('/service')}">서비스기능</a></li>
            <li><a @click="$router.push({name:'Guide1'})" :class="{focus:$route.path.startsWith('/guide')}">이용안내</a></li>
<!--            <li><a @click="$router.push({name:'Faq'})">고객센터</a></li>-->
          </ul>
        </nav>
        <!-- <div class="gnb-info">
            <a href="login/login_seller.html">로그인</a>
            <a href="register/register_business.html">회원가입</a>
        </div> -->
        <div class="mobile-btn" id="mobile-btn" @click="mobileToggle" :class="{active:mobileOn}">
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>
        <nav class="gnb-mb" :class="{active:mobileOn}" @click.self="mobileOn=false">
          <div class="container">
            <ul>
              <li><a @click="$router.push({name:'About'})">결제나라</a></li>
              <li><a @click="$router.push({name:'ServiceQuick'})">서비스기능</a></li>
              <li><a @click="$router.push({name:'Guide1'})">이용안내</a></li>
<!--              <li><a @click="$router.push({name:'Faq'})">고객센터</a></li>-->
            </ul>
            <!-- <ul class="mb-gnb-info">
                <li><a href="login/login_seller.html">로그인</a></li>
                <li><a href="register/register_business.html">회원가입</a></li>
            </ul> -->
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isPinkBg: false,
      mobileOn: false,
    }
  },
  mounted() {
    if (this.$route.name !== 'Main') {
      this.isPinkBg = true
    } else {
      this.isPinkBg = false
    }
  },
  methods: {
    mobileToggle() {
      this.mobileOn = !this.mobileOn
      // $(this).toggleClass('active');
      //
      // if ($(this).hasClass("active")) {
      //   mobileBg.addClass("mb-bg");
      //   mobileMenu.addClass("active");
      // } else {
      //   mobileBg.removeClass("mb-bg");
      //   mobileMenu.removeClass("active");
      // }
    }
  },
  watch: {
    '$route.name': {
      handler: function(val) {
        this.mobileOn = false

        console.log(this.$route.path)
        if (val !== 'Main') {
          this.isPinkBg = true
        } else {
          this.isPinkBg = false
        }
      }
    }
  }
}
</script>

<style scoped>
.bg-pink-head { background: #FF768D; }
</style>

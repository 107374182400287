import Vue from 'vue'
import App from './App.vue'
import router from './router'

router.beforeEach((to, from, next) => {

//현시점에서는 미사용 - 2021.10.26
  /*
    let tokenMiddle = [ // 로그인후 이용가능한 화면 추가
    ]

    if(tokenMiddle.indexOf(to.name) !== -1) {
      let token = sessionStorage.getItem('accessToken')
      if(!token) {
        //로그인 요청할 화면으로 이동
        // next({ name: 'BeforeLogin' })
      }
    }
  */

  window.scrollTo({top:0})
  next()
})

Vue.config.productionTip = false


import api from './commonjs/api';
Vue.use(api)

import common from "@/commonjs/common";
Vue.use(common)

import event from './commonjs/event';
Vue.use(event)

import vueMoment from 'vue-moment'
Vue.use(vueMoment)


const EventBus = new Vue();
export default EventBus

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

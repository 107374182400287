<template>
  <main class="sub-main">
    <!-- ======= section1 About Visual ======= -->
    <section class="about section1">
      <div class="about-visual">
        <div class="container ">
          <div class="about-content">
            <div class="about-tit">
              <h2>생활 속 유용한 나만의 결제 솔루션,<br><em class="point-color">페이허브 페이먼츠</em></h2>
              <p>페이허브 하나면 사이트 결제 연동은 물론, 온라인/오프라인 결제 서비스와 주문서/쇼핑몰 등 다양한 부가 서비스까지 무료로 통합 이용 가능합니다.</p>
            </div>
            <div class="about-btn">
              <button>
                <a href=""><img src="/image/google.svg" alt="구글스토어"></a>
              </button>
              <button>
                <a href=""><img src="/image/app.svg" alt="앱스토어"></a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 Advantages ======= -->
    <section class="about section2">
      <h2 class="hide">페이허브 소개</h2>
      <article class="advantages bg-white">
        <div class="container">
          <div class="row relative">
            <div class="col-sm-12 col-md-6">
              <div class="advantages-txt">
                <span>01</span>
                <h3>누구나 만족하는 결제 솔루션</h3>
                <p>가맹점 사장님부터 결제 사용자까지<br>
                  모든 이들이 만족하는 통합 결제 솔루션을 사용해보세요. </p>
              </div>
            </div>
            <div class="advantages-img-r col-sm-12 col-md-6">
              <div class="advantages-img">
                <img src="/image/about-1.png" alt="누구나 만족하는 결제 솔루션">
              </div>
            </div>
          </div>
        </div>
      </article>
      <article class="advantages bg-gray">
        <div class="container">
          <div class="row relative advant-order">
            <div class="advantages-img-r col-sm-12 col-md-6">
              <div class="advantages-img">
                <img src="/image/about-2.png" alt="온/오프라인 등 다양한 결제 서비스">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="advantages-txt">
                <span>02</span>
                <h3>온라인 결제 서비스</h3>
                <p>각각의 상점에 최적화된 안전하고 빠른 결제 방식을
                  확인하세요.</p>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article class="advantages bg-white">
        <div class="container">
          <div class="row relative">
            <div class="col-sm-12 col-md-6">
              <div class="advantages-txt">
                <span>03</span>
                <h3>관리가 쉬운 매출/정산내역</h3>
                <p>결제 조회, 결제 취소, 정산 기능이 포함된<br>
                  관리자 페이지 제공으로 고객님의 고충을 해결합니다.</p>
              </div>
            </div>
            <div class="advantages-img-r col-sm-12 col-md-6">
              <div class="advantages-img">
                <img src="/image/about-3.png" alt="관리가 쉬운 매출/정산내역">
              </div>
            </div>
          </div>
        </div>
      </article>
      <article class="advantages bg-gray">
        <div class="container">
          <div class="row relative advant-order">
            <div class="advantages-img-r col-sm-12 col-md-6">
              <div class="advantages-img">
                <img src="/image/about-4.png" alt="믿을 수 있는 안전한 보안 시스템">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="advantages-txt">
                <span>04</span>
                <h3>믿을 수 있는 안전한 보안 시스템</h3>
                <p>쉽고 빠른 PG 결제 서비스로 만족하지 않습니다.<br>
                  안전함을 밑바탕으로 항상 최고의 보안을 유지하기
                  위해 노력합니다.</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section><!-- End section2 -->

    <!-- ======= section3 Free join ======= -->
    <section class="about section3 join-bg">
      <div class="container">
        <h4>누구나 쉽게 사용하세요!</h4>
        <p>페이허브는 금융 및 IT 전문가들의 노하우와 업계 최고 수준의 시스템 인프라,
          장애 없는 24시간 서비스, 강력한 보안을 통해 독자적인 결제시스템을 운영합니다.</p>
        <button class="outline-big-btn">
          무료가입
        </button>
      </div>
    </section><!-- End section3 -->

  </main>
</template>

<script>
export default {
  name: "About",
  path: '/about/about',
}
</script>

<style scoped>

</style>

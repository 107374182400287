<template>
  <div>
    <div class="modal_popup" v-show="showModal">

      <div class="modal_wrap" v-show="showModal">
        <div class="modal_header">
          <h3>등록 심사 업종 기준</h3>
          <button class="modal_popup_close icon-close" @click="showModal=false"></button>
        </div>
        <div class="modal_cont">
          <div class="text_box">
            <h4>불가업종</h4>
            <p>경마, 경륜, 대출, 도박/성인사이트(음란물), 기타 미풍양속을 저해하는 업종, 선불카드, 순금/순은, 환금성, 의약품, 저가경매, 휴대폰가입을포함한판매</p>
          </div>
          <div class="text_box">
            <h4>리스크업종</h4>
            <p>구매대행, 귀금속(30만원이상), 금연보조제, 전자담배, 다단계, 담배(전자담배, 해외고객대상 판매 가능), 로또번호 생성 서비 스, 명품, 모바일쿠폰/기프티콘, 미곡상(20만원 이상), 부적, 상품권/복권, 성인사이트 컨텐츠, 성인용품 사이트, 소셜커머스, 아이템, 웨하드, 채팅, 총포/도검, 호스팅/광고, 콘도회원권, 인터넷쇼핑몰분양, 공동구매, 건강/다이어트식품, 광고대행</p>
          </div>
          <div class="text_box">
            <h4>유의업종</h4>
            <p>게임, 경매, 군복 및 군장도구, 도수 있는 안경/썬글라스/콘택트 렌즈, 동창회비, 사이버머니(10만원 초과), 소프트웨어, 숙박, 숙박여행, 식물, 신용카드단말기, 애완동물, 온라인강의, 유흥 구인구직, 전자여권수속(ESTA), 전통주, 정기주차권, 정보 서비스, 주류, 중고차, 증명서 발급, 참가비/회비, 티켓발급 (이미용, 교통, 항공), 후원금</p>
          </div>
        </div>
      </div>
    </div>
    <main class="sub-main">
      <!-- ======= section1 tab ======= -->
      <section class="section-80 guide section1">
        <div class="container">
          <div class="function-tab">
            <ul>
              <li><a @click="$router.push({name:'Guide1'})"><span>비용안내</span></a></li>
              <li><a @click="$router.push({name:'Guide2'})"><span>가입·결제받기</span></a></li>
              <li class="fucus"><a @click="$router.push({name:'Guide3'})"><span>구비서류·심사</span></a></li>
              <li><a @click="$router.push({name:'Guide4'})"><span>보증보험</span></a></li>
              <li><a @click="$router.push({name:'Guide5'})"><span>정산안내</span></a></li>
            </ul>
          </div>
        </div>
      </section><!-- End section1 -->
      <!-- ======= section2 guid3 ======= -->
      <section class="guide section2">
        <div class="container guid">
          <!-- 구비서류·심사 -->
          <div class="heading-tit">
            <h2>구비서류·심사</h2>
            <p>결제 받으시고 1~2일 내에 서류를 제출하세요.</p>
          </div>
          <div class="panel-block">
            <div class="panel-block-area">
              <p class="check">회원가입 시 계약서류 제출이 필요합니다. 서류검토 과정에서 간단한 업종심사가 이어집니다. <br>
                결제규모 및 업종에 따라 보증보험을 요청 드릴 수 있습니다.</p>
              <p class="check">서류접수 후 심사완료까지 영업일로 3~5일 정도 소요될 수 있으며, 서류팀에서 순차적으로 심사 후 유선으로 연락 드립니다.
                결제 받으시고 1~2일 내에 서류를 보내주시는 게 가장 좋습니다.</p>
            </div>
          </div>
          <!-- 제출서류 -->
          <div class="panel">
            <h3>제출서류</h3>
            <div class="line">
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  법인사업자
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <p>
                    인감 날인된 계약서 2부 / 법인인감증명서 1부 / 사업자등록증 사본 1부 / 법인 통장사본 1부
                  </p>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  개인사업자
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <p>
                    날인된 계약서 2부 / 사업자등록증 사본 1부 / 신분증 사본(앞/뒷면) / 통장사본 1부
                  </p>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  사업자 계약서
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <div class="panel-list-btn">
                    <button class="table-btn">다운로드 PDF</button>
                    <button class="table-btn">다운로드 JPG</button>
                  </div>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  개인판매자
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <p>
                    날인된 계약서 2부 / 신분증 사본(앞/뒷면) / 통장사본 1부
                  </p>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  개인 계약서
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <div class="panel-list-btn">
                    <button class="table-btn">다운로드 PDF</button>
                    <button class="table-btn">다운로드 JPG</button>
                  </div>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  계약서 날인안내
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <div class="panel-list-btn">
                    <button class="table-btn">계약서 날인 방법</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="ex-list">
              <ul>
                <li>유의업종 또는 리스크 업종의 경우 담당자가 추가 증빙을 요청 드릴 수 있습니다. <a href="">업종 기준 보기</a></li>
              </ul>
            </div>
          </div>
          <!-- 제출안내 -->
          <div class="panel">
            <h3>제출안내</h3>
            <div class="line">
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  이메일 접수
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <p class="pb-4">
                    구비서류(계약서 포함)를 한 장씩 스캔 또는 스마트폰 촬영 후 아래 주소로 제출해 주시기 바랍니다.
                  </p>
                  <p>
                    이메일 <a href="mailto:cs@payhub.co.kr">cs@payhub.co.kr</a>
                  </p>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  우편접수
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <p class="pb-4">
                    구비서류(계약서 포함)를 프린트 후 작성해서 아래 주소로 발송해 주시기 바랍니다.
                  </p>
                  <p>
                    주소 [38516] 경상북도 경산시 둥지로 18길 19, 401호(임당동, 엘엔빌딩)
                  </p>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  업종심사
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <p class="pb-4">
                    계약서류 검토와 함께 간단한 업종심사가 이어집니다.
                  </p>
                  <p class="pb-4 point-color">
                    유의업종 및 리스크업종의 경우 결제수수료가 인상되며, 불가업종의 경우 결제가 취소될 수 있습니다.
                  </p>
                  <div class="panel-list-btn" id="modal_btn">
                    <button class="table-btn" @click="showModal=true">유의 및 불가업종 보기</button>
                  </div>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                  보증보험
                </div>
                <div class="panel-list-desc col-sm-12 col-md-10">
                  <p class="pb-4">
                    결제규모와 업종에 따라 보증보험 신청을 요청 드릴 수 있습니다.
                  </p>
                  <div class="panel-list-btn">
                    <button class="table-btn">보증보험 안내 보기</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section><!-- End guid3 -->
    </main>
  </div>
</template>

<script>
export default {
  name: "Guide3",
  path: '/guide/3',
  data() {
    return {
      showModal: false,
    }
  },
}
</script>

<style scoped>

</style>

import axios from 'axios';

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
        },
        timeout: 60000
    }
}
let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN + "/api/v1"

        Vue.prototype.$FAQ = "/faq"
        Vue.prototype.$FAQ_CATEGORY = "/faq/category"

        Vue.prototype.$NOTICE = "/notice"
        // Vue.prototype.$NOTICE_DETAIL = "/notice/"

        Vue.prototype.$post = function (callUrl, caller, postData, success, fail) {
            console.log('test url' + callUrl)

            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$niceAuth = function (success, fail) {
            axios.post("https://nice.payhub.co.kr/check-pay-plus/request", null, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                timeout: 60000
            }).then((response) => {
                if (response.data.success) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch(e => {
                console.log(e)
                fail(e.response)
            })
        }
    }
})

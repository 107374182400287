<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 support  section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'Faq'})"><span>자주묻는 질문</span></a></li>
            <li><a @click="$router.push({name:'Notice'})"><span>공지사항</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'InquiryList'})"><span>문의사항</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 Lnquiry-search ======= -->
    <section class="support section2">
      <div class="container">
        <div class="heading-tit d-notice">
          <h2>문의사항</h2>
          <div class="search-input">
            <input type="text" placeholder="검색어를 입력하세요.">
            <button type="button" class="btn-search">
              <img src="/image/icon_search.svg" alt="검색">
            </button>
          </div>
        </div>
        <div class="support-content">
          <div class="support-tab">
            <ul>
              <li class="focus" onclick="location.href='inquiry_seller_write.html'">판매자 회원</li>
              <li onclick="location.href='inquiry_buyer_write.html'">결제고객(구매자)</li>
            </ul>
          </div>
          <div class="support-info">
            <h5>문의 전 안내사항</h5>
            <div class="support-box">
              <p class="info">
                <strong>Q. 가입 후 해야할 일이 있나요?</strong>
                가입 후 결제 사용은 바로 가능하나, 계약서 및 구비서류를 발송해주셔야 정산이 가능합니다.<br>
                계약서는 <b class="point-color">결제나라 홈페이지 > 이용안내 > 구비서류/심사페이지</b>에서 출력할 수 있으며, 작성 날인 하여 <a href="mailto:cs@payhub.co.kr">cs@payhub.co.kr</a> 이메일로 접수해주시기 바랍니다.
              </p>
              <p class="info">
                <strong>Q. 계약서를 이메일로 발송했는데 언제 처리되나요?</strong>
                계약서는 발송해주신 순서대로 서류심사팀에서 확인되어 완료까지는 <b class="point-color">최대 일주일 정도 소요</b>될 수 있습니다. 완료 또는 수정 사항이 있을 경우 유선이나 문자, 메일로 연락드리니 조금만 기다려 주시기 바랍니다.
              </p>
              <p class="info">
                <strong>Q. 정산은 언제 되나요?</strong>
                <b class="text-black">결제일로부터</b><b class="point-color">D+5(영업일기준)</b> 가 정산일 입니다. 계약서 완료 후 정산이 가능하니 참고하시기 바랍니다.
              </p>
              <p class="info">
                <strong>Q. 왜 정산 불가인가요?</strong>
                계약서가 완료되지 않았거나, 보증보험 증권 등 여러가지 사유가 있을 수 있기 때문에 오른쪽 아래 <b class="point-color">채팅상담</b>으로 문의주시거나, <b class="point-color">1800-3772</b>로 연락하시는 게 가장 빠르고 정확합니다.
              </p>
            </div>
            <div>
              <div class="form">
                <div class="container">
                  <div class="form-item row">
                    <label class="label col-3">문의 유형</label>
                    <div class="col-9 selectwrap pl-0 pr-0">
                      <select>
                        <option value="문의 유형을 선택하세요. (일반문의 / 연동문의)">문의 유형을 선택하세요. (일반문의 / 연동문의)</option>
                        <option value="일반문의">일반문의</option>
                        <option value="연동문의">연동문의</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">제목</label>
                    <input class="input-inquiry col-9" type="text" placeholder="제목을 입력해주세요.">
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">내용</label>
                    <textarea class="textarea-inquiry col-9" placeholder="내용을 입력해주세요."></textarea>
                  </div>
                  <div class="form-item row">
                    <div class="label col-3">파일첨부</div>
                    <div class="filebox col-9 pl-0 pr-0 d-flex">
                      <input class="upload-name" placeholder="파일을 첨부해주세요. / 최대 2MB">
                      <label for="file">첨부파일</label>
                      <input type="file" id="file">
                    </div>
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">작성자</label>
                    <input class="input-inquiry col-9" type="text" placeholder="아름을 입력해주세요.">
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">비밀번호</label>
                    <input class="input-inquiry col-9" type="password" placeholder="비밀번호를 입력해주세요.">
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">자동등록방지</label>
                    <div class="input-inquiry-capcha col-9">
                      <div class="capcha-area">
                        <div class="capcha-img">
                          <img src="/image/capcha-img.png" alt="자동등록방지 이미지">
                        </div>
                        <div class="capcha-con">
                          <input class="capcha-input" type="text" placeholder="보안문자를 입력해주세요.">
                          <button type="button" class="button-refresh">
                            <i class="sprites refresh"></i><span>새로고침</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-btn list-btn-box justify-content-center">
            <div class="button-con">
              <button type="button" class="middle-btn">등록</button>
              <button type="button" class="outline-middle-btn ml-3">취소</button>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End Lnquiry-search -->
  </main>
</template>

<script>
export default {
name: "InquirySellerWrite",
  path: '/support/inquiry/seller/write',
}
</script>

<style scoped>

</style>

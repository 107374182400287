<template>
  <main class="main100">
    <div class="para-80">
      <div class="container">
        <div class="container-content">

          <div class="terms">

            <!-- 탭 -->
            <div class="function-tab function-tab3 mb-50">
              <ul>
                <li>
                  <a @click="$router.push({name:'Term1'})">
                    <span>서비스 이용약관</span></a>
                </li>
                <li>
                  <a @click="$router.push({name:'Term2'})">
                    <span>개인정보처리방침</span></a>
                </li>
                <li class="fucus">
                  <a @click="$router.push({name:'Term3'})">
                    <span>전자금융거래 이용약관</span></a>
                </li>
              </ul>
            </div>

            <!-- 전자금융거래 이용약관 -->
            <div class="function-cont">
              <h2 class="term-title mt-30 mb-50">전자금융거래 이용약관</h2>
              <div class="terms-content">
                <div class="terms-top-box">
                  <p>결제나라 전자금융거래 이용 약관입니다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제1조 (목적)</h5>
                  <p class="terms-description">이 약관은 주식회사 페이허브(이하 '회사'라 합니다)가 제공하는 전자지급결제대행서비스를 이용자가 이용함에 있어 회사와 이용자 사이의
                    전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제2조 (용어의 정의)</h5>
                  <p class="terms-description">본 약관에서 정한 용어의 정의는 아래와 같습니다.</p>
                  <ol class="terms-list-nodep">
                    <li>‘전자금융거래’라 함은 회사가 전자적 장치를 통하여 전자금융업무를 제공하고, 회원이 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는
                      거래를 말합니다.
                    </li>
                    <li>‘전자지급수단’이라 함은 선불전자지급수단, 신용카드 등 전자금융거래법 제2조 제11호에서 정하는 전자적 방법에 따른 지급수단을 말합니다.
                    </li>
                    <li>‘전자지급거래’라 함은 자금을 주는 자(이하 ‘지급인’이라 합니다)가 회사로 하여금 전자지급수단을 이용하여 자금을 받는 자(이하 ‘수취인’이라 합니다)에게 자금을 이동하게
                      하는 전자금융거래를 말합니다.
                    </li>
                    <li>‘전자적 장치’라 함은 전자금융거래정보를 전자적 방법으로 전송하거나 처리하는데 이용되는 장치로서 현금자동지급기, 자동입출금기, 지급용단말기, 컴퓨터, 전화기 그 밖에 전자적
                      방법으로 정보를 전송하거나 처리하는 장치를 말합니다.
                    </li>
                    <li>‘접근매체’라 함은 전자금융거래에 있어서 거래 지시를 하거나 회원 및 거래 내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는
                      전자적 정보(신용카드번호를 포함합니다), 전자서명법 상의 전자서명생성정보 및 인증서,
                      금융기관 또는 전자금융업자에 등록된 회원번호, 회원의 생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을
                      말합니다.
                    </li>
                    <li>‘전자금융거래서비스’라 함은 회사가 회원에게 제공하는 제4조 기재의 서비스를 말합니다.</li>
                    <li>‘회원’이라 함은 본 약관에 동의하고 본 약관에 따라 회사가 제공하는 전자금융거래서비스를 이용하는 이용자를 말합니다.</li>
                    <li>‘거래지시’라 함은 회원이 본 약관에 따라 회사에게 전자금융거래의 처리를 지시하는 것을 말합니다.</li>
                    <li>‘오류’라 함은 회원의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 회원의 거래지시에 따라 이행되지 아니한 경우를 말합니다.</li>
                  </ol>
                  <p class="terms-description">이 약관에서 별도로 정하지 아니한 용어의 정의는 「전자금융거래법」 및 「전자금융거래법 시행령」, 금융위원회의 전자금융감독규정 및
                    전자금융감독규정 시행세칙에서 정하는 바에 따른다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제3조 (약관의 명시 및 변경)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 이용자가 전자금융거래 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수 있도록 합니다.</li>
                    <li>회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에 의하여 본 약관의 사본을 이용자에게 교부합니다.</li>
                    <li>회사가 약관을 변경하는 때에는 그 시행일 1개월 전에 변경되는 약관을 회사가 제공하는 전자금융거래 서비스 이용 초기화면에 게시함으로써 이용자에게 공지합니다.
                    </li>
                    <li>회사는 제3항의 공지를 할 경우 "이용자가 변경에 동의하지 아니한 경우 공지한 날로부터 30일 이내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는
                      변경에 동의한 것으로 본다" 라는 취지의 내용을 고지하거나 통지합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제4조 (전자지급결제대행서비스의 종류)</h5>
                  <p class="terms-description">회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다.</p>
                  <ol class="terms-list-nodep">
                    <li>신용카드결제대행서비스 : 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우로서, 회사가 전자결제시스템을 통하여 신용카드 지불정보를 송ㆍ수신하고
                      결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다.
                    </li>
                    <li>계좌이체대행서비스 : 이용자가 결제대금을 회사의 전자결제시스템을 통하여 금융기관에 등록 정산을 대행하거나 매개하는 서비스를 말합니다. 자신의 계좌에서 출금하여 원하는 계좌로
                      이체할 수 있는 실시간 송금 서비스를 말합니다.
                    </li>
                    <li>가상계좌서비스 : 이용자가 결제대금을 현금으로 결제하고자 경우 회사의 전자결제시스템을 통하여 자동으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이
                      이루어지는 서비스를 말합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제5조 (거래내용의 확인)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 개별 서비스 내 회원 정보 조회 화면을 통하여 회원의 거래내용(회원의 오류정정 요구사실 및 처리 결과에 관한 사항을 포함합니다)을 확인할 수 있도록 하며, 회원이
                      거래내용에 대해 서면교부를 요청하는 경우에는 요청을 받은 날로부터 2주 이내에 팩스,
                      우편 또는 직접 교부의 방법으로 거래 내용에 관한 서면을 교부합니다.
                    </li>
                    <li>회사는 제1항에 따른 회원의 거래내용 서면 교부 요청을 받은 경우 전자적 장치의 운영장애, 그 밖의 이유로 거래내용을 제공할 수 없는 때에는 즉시 회원에게 전자문서
                      전송(전자우편을 이용한 전송을 포함한다)의 방법으로 그러한 사유를 알려야 하며,
                      전자적 장치의 운영장애 등의 사유로 거래내용을 제공할 수 없는 기간은 제1항의 거래내용에 관한 서면의 교부기간에 산입하지 아니합니다.
                    </li>
                    <li>제1항의 대상이 되는 거래내용 중 대상기간이 5년인 것은 다음 각호와 같습니다.
                      <ul class="terms-list-dep2">
                        <li>1) 거래계좌의 명칭 또는 번호</li>
                        <li>2) 전자금융거래의 종류 및 금액</li>
                        <li>3) 전자금융거래상대방을 나타내는 정보</li>
                        <li>4) 전자금융거래일시</li>
                        <li>5) 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보</li>
                        <li>6) 회사가 전자금융거래의 대가로 받은 수수료</li>
                        <li>7) 회원의 출금 동의에 관한 사항</li>
                        <li>8) 해당 전자금융거래와 관련한 전자적 장치의 접속기록</li>
                      </ul>
                    </li>
                    <li>제1항의 대상이 되는 거래내용 중 대상기간이 1년인 것은 다음 각 호와 같습니다.
                    <ul class="terms-list-dep2">
                      <li>1) 회원의 오류정정 유구사실 및 처리결과에 관한 사항</li>
                      <li>2) 기타 금융위원회가 고시로 정한 사항</li>
                    </ul>
                    </li>
                    <li>회원이 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있습니다.</li>
                    <ul class="terms-list-nodep manager-info list-dotted">
                      <li>주소 : 경상북도 경산시 둥지로 18길 19, 401호</li>
                      <li>사이트 주소 : https://payhub.co.kr/</li>
                      <li>전화번호 : 070-7591-8896</li>
                    </ul>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제6조 (전자금융거래 기록의 생성 및 보존)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 회원이 이용한 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.</li>
                    <li>제1항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및 보존 기간은 제5조 제3항 및 제4항에 따릅니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제7조 (이용시간)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를 제공함을 원칙으로 합니다. 단, 금융기관 기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다.</li>
                    <li>회사는 정보통신설비의 보수, 점검이나 기타 기술상의 필요에 따라 금융기관 기타 결제수단 발행 업자의 사정에 의하여 서비스 중단이 불가피한 경우, 서비스 중단 3일 전까지 게시 가능한 전자적 수단을 통하여 서비스 중단 사실을 게시한 후 서비스를 일시 중단할 수 있습니다.
                      다만, 시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시없이 서비스를 중단할 수 있습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제8조 (접근매체의 선정과 사용 및 관리)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 전자금융거래 서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수 있습니다. 회사는 이용자의 해당정보를 무단으로 이용하지 않으며, 이용자의 요구 시에는 즉각 폐기하여 어떠한 용도로도 사용할 수 없도록 합니다.</li>
                    <li>이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공할 수 없습니다.</li>
                    <li>이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.
                      회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근 매체를 사용함으로써 이용자에게 발생한 손해를 배상할 책임이 있습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제9조 (거래지시의 철회)</h5>
                  <ol class="terms-list-nodep">
                    <li>회원이 회사의 전자금융거래서비스를 이용하여 전자지급거래를 한 경우, 회원은 지급의 효력이 발생하기 전까지 본 약관에서 정한 바에 따라 제5조 제5항에 기재된 주소, 전화번호를 이용한 우편, 전화연락의 방법으로 또는 개별 서비스 내 철회에 의한 방법으로 거래 지시를 철회할 수 있습니다.</li>
                    <li>회원은 전자지급의 효력이 발생한 경우에 전자상거래등에서의 소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법에 따라 결제대금을 반환 받을 수 있습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제10조 (오류의 정정 등)</h5>
                  <ol class="terms-list-nodep">
                    <li>이용자는 전자금융거래 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할 수 있습니다.</li>
                    <li>회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에 그 결과를 이용자에게 알려 드립니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제11조 (회사의 책임)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 다음 각호의 사고로 인하여 회원에게 손해가 발생한 경우에는 그 손해를 배상할 책임을 부담합니다.
                    <ul class="terms-list-dep2">
                      <li>1) 접근매체의 위조나 변조로 발생한 사고</li>
                      <li>2) 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서 발생한 사고</li>
                      <li>3) 전자금융거래를 위한 전자적 장치 또는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 접근매체의 이용으로 발생한 사고</li>
                    </ul>
                    </li>
                    <li>회사는 제1항에도 불구하고 다음 각호의 경우에는 그 책임의 전부 또는 일부를 회원이 부담하게 할 수 있습니다.
                    <ul class="terms-list-dep2">
                      <li>1) 회원이 접근매체를 제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나 담보의 목적으로 제공한 경우</li>
                      <li>2) 제3자가 권한 없이 회원의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 쉽게 알 수 있었음에도 불구하고 접근매체를 누설하거나 노출 또는 방치한 경우</li>
                      <li>3) 법인(중소기업기본법제2조 제2항에 의한 소기업을 제외한다)인 회원에게 손해가 발생한 경우로서 회사가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의 의무를 다한 경우</li>
                    </ul>
                    </li>
                    <li>회사는 회원으로부터 거래 지시가 있음에도 불구하고 천재지변, 회사의 귀책사유가 없는 기타의 불가항력적인 사유로 처리 불가능하거나 지연된 경우로서 회원에게 처리 불가능 또는 지연사유를 통지한 경우
                      (금융기관 또는 결제수단발행업자나 통신판업자가 통지한 경우를 포함합니다)에는 회원에 대해 이로 인한 책임을 부담하지 않습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제12조 (전자지급거래계약의 효력)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 이용자의 거래 지시가 전자지급거래에 관한 경우 그 지급절차를 대행하며, 전자지급거래에 관한 거래지시의 내용을 전송하여 지급이 이루어지도록 합니다.</li>
                    <li>회사는 이용자의 전자지급거래에 관한 거래지시에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 이용자에게 반환하여야 합니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제13조 (전자금융거래정보의 제공금지)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 전자금융거래서비스를 제공함에 있어서 취득한 회원의 인적사항, 회원의 계좌, 접근매체 및 전자금융거래의 내용과 실적에 관한 정보
                      또는 자료를 금융실명법 등 법령에 의하거나 회원의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무 상 목적 외에 사용하지 아니합니다.
                    </li>
                    <li>회사는 회원이 안전하게 전자금융거래서비스를 이용할 수 있도록 회원의 개인정보보호를 위하여 개인정보취급방침을 운용합니다. 회사의 개인정보취급방침은 회사의 홈페이지 또는 개별 서비스에 링크된 화면에서 확인할 수 있습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제14조 (분쟁처리 절차)</h5>
                  <ol class="terms-list-nodep">
                    <li>회원은 회사의 서비스 페이지 하단에 게시된 분쟁처리 담당자 또는 연락처를 통하여 전자금융거래와 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.</li>
                    <li>회원이 회사에 대하여 분쟁처리를 신청한 경우 회사는 15일 이내에 이에 대한 조사 또는 처리 결과를 회원에게 안내 합니다. 단, 착오송금중개 및 해외가맹점 분쟁의 경우 접수일로부터 31일 이내에 회원에게 안내 합니다.</li>
                    <li>회원은 회사의 분쟁처리결과에 대하여 이의가 있을 경우 금융위원회의 설치 등에 관한 법률 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회나 소비자기본법 제60조 제1항의 규정에 따른 한국소비자원의 소비자분쟁조정위원회에
                      회사의 전자금융거래서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제15조 (회사의 안정성 확보 의무)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융감독위원회가 정하는 기준을 준수합니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제16조 (약관 외 준칙 및 관할)</h5>
                  <ol class="terms-list-nodep">
                    <li>이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.</li>
                    <li>회사와 이용자간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.</li>
                  </ol>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
export default {
  name: "Term3",
  path: '/term/3',
}
</script>

<style scoped>

</style>

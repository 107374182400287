<template>
  <div class="container justify-content-center d-flex h100">
    <div class="row">
      <div class="align-self-center">
        <div class="w500">
          <div class="login-title mb-4">
            <a @click="$router.push({name:'Main'})">
              <img class="img-responsive" src="/image/bi_pink.png" alt="결제나라">
            </a>
          </div>
          <!-- 탭 -->
          <div class="login-tabs">
            <ul>
              <li>
                <a class="dark-gray" @click="$router.push({name:'FindId'})">아이디 찾기</a>
              </li>
              <li class="on">
                <a class="dark-gray" @click="$router.push({name:'FindPw'})">비밀번호 찾기</a>
              </li>
            </ul>
          </div>

          <div class="tab-content">
            <!-- 비밀번호 찾기 -->
            <div class="tab-pane">
              <form class="my-4">
                <input class="input-bot-line" type="text" placeholder="아이디">
                <input class="input-bot-line" type="tel" placeholder="휴대전화번호">
                <button class="w100 d-block big-btn mt-30 mb-30" type="button">비밀번호 찾기</button>
              </form>

              <div class="applink d-flex col-xs-12 justify-content-center">
                <button type="button" class="mr-3">
                  <span><img src="/image/icon-google.png" alt="구글 플레이"></span>
                  Google Play</button>
                <button type="button">
                  <span><img src="/image/icon-macos.png" alt="앱스토어"></span>
                  App Store</button>
              </div>
              <div class="para-30">
                <div class="login-link">
                  <div class="d-flex d-block justify-content-between">
                    <a @click="$router.push({name:'LoginSeller'})">결제나라 로그인</a>
                    <a @click="$router.push({name:'RegisterTerm'})">회원가입</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- 비밀번호 찾기 여기까지 -->
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "FindPw",
  path: '/find/password',
}
</script>

<style scoped>

</style>

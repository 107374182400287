<template>
  <main class="main100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-xs-12">
          <div class="para-50">
            <div class="login-title">
              <a @click="$router.push({name:'Main'})">
                <img class="img-responsive mb-30" src="/image/bi_pink.png" alt="결제나라">
              </a>
              <h2>[ <strong>판매자 회원가입</strong> ]</h2>
            </div>
          </div>
          <div class="para-50">
            <h3 class="register-term-title font-weight-bold">페이허브 이용약관</h3>
            <div class="terms-box">
              <div class="terms-boxwrap">

                <div class="terms-wrap">
                  <h5>제1조 (목적)</h5>
                  <p class="terms-description">이 약관은 페이허브페이먼츠(이하 '회사'라 한다.)가 제공하는 전자지급결제대행서비스 및
                    결제대금예치서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.</p>
                </div>
                <div class="terms-wrap mb-0">
                  <h5>제2조 (용어의 정의)</h5>
                  <p class="terms-description">이 약관에서 정하는 용어의 정의는 다음과 같습니다.</p>
                  <ol class="terms-list-nodep">
                    <li>'전자금융거래'라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스(이하 '전자금융거래 서비스'라고 한다.)를
                      제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.</li>
                    <li>'전자지급결제대행서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 또는 그
                      대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</li>
                    <li>'결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다.)의 전부 또는 일부를
                      재화 또는 용역(이하 '재화 등'이라 한다.)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점까지
                      결제대금을 예치하는 서비스를 말합니다.</li>
                    <li>'이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.</li>
                    <li>'접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는
                      정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다.), '전자서명법'상의 인증서, 회사에 등록된 이용자번호, 이용자의
                      생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.</li>
                    <li>'거래지시'라 함은 이용자가 본 약관에 의하여 체결되는 전자금융거래계약에 따라 회사에 대하여 전자금융거래의 처리를 지시하는 것을 말합니다.</li>
                    <li>'오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="checks my-3 pb-2">
              <input type="checkbox" id="agree1" value="agree1" class="mr-3">
              <label for="agree1">이 약관에 동의합니다.</label>
            </div>
          </div>
          <div class="para-50">
            <h3 class="register-term-title font-weight-bold">전자금융거래 이용약관</h3>
            <div class="terms-box">
              <div class="terms-boxwrap">
                <div class="terms-wrap mb-0">
                  <div class="terms-wrap">
                    <h5>제1조 (목적)</h5>
                    <p class="terms-description">이 약관은 (주)페이허브(이하 '회사'라 한다.)가 제공하는 전자지급결제대행서비스 및
                      결제대금예치서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.</p>
                  </div>
                  <h5>제2조 (용어의 정의)</h5>
                  <p class="terms-description">이 약관에서 정하는 용어의 정의는 다음과 같습니다.</p>
                  <ol class="terms-list-nodep">
                    <li>'전자금융거래'라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스(이하 '전자금융거래 서비스'라고 한다.)를
                      제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.</li>
                    <li>'전자지급결제대행서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 또는 그
                      대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</li>
                    <li>'결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다.)의 전부 또는 일부를
                      재화 또는 용역(이하 '재화 등'이라 한다.)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점까지
                      결제대금을 예치하는 서비스를 말합니다.</li>
                    <li>'이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.</li>
                    <li>'접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는
                      정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다.), '전자서명법'상의 인증서, 회사에 등록된 이용자번호, 이용자의
                      생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.</li>
                    <li>'거래지시'라 함은 이용자가 본 약관에 의하여 체결되는 전자금융거래계약에 따라 회사에 대하여 전자금융거래의 처리를 지시하는 것을 말합니다.</li>
                    <li>'오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="checks my-3 pb-2">
              <input type="checkbox" id="agree2" value="agree2" class="mr-3">
              <label for="agree2">이 약관에 동의합니다.</label>
            </div>
          </div>
          <div class="para-50">
            <h3 class="register-term-title font-weight-bold">개인정보 수집 / 이용안내</h3>
            <div class="terms-box">
              <div class="terms-boxwrap">
                <div class="terms-wrap mb-0">
                  <div class="terms-wrap">
                    <h5>제1조 (목적)</h5>
                    <p class="terms-description">이 약관은 (주)페이허브(이하 '회사'라 한다.)가 제공하는 전자지급결제대행서비스 및
                      결제대금예치서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을 정함을 목적으로 합니다.</p>
                  </div>
                  <h5>제2조 (용어의 정의)</h5>
                  <p class="terms-description">이 약관에서 정하는 용어의 정의는 다음과 같습니다.</p>
                  <ol class="terms-list-nodep">
                    <li>'전자금융거래'라 함은 회사가 전자적 장치를 통하여 전자지급결제대행서비스 및 결제대금예치서비스(이하 '전자금융거래 서비스'라고 한다.)를
                      제공하고, 이용자가 회사의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이용하는 거래를 말합니다.</li>
                    <li>'전자지급결제대행서비스'라 함은 전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는 것 또는 그
                      대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</li>
                    <li>'결제대금예치서비스'라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한다.)의 전부 또는 일부를
                      재화 또는 용역(이하 '재화 등'이라 한다.)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용 확인 시점까지
                      결제대금을 예치하는 서비스를 말합니다.</li>
                    <li>'이용자'라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.</li>
                    <li>'접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는
                      정보로서 전자식 카드 및 이에 준하는 전자적 정보(신용카드번호를 포함한다.), '전자서명법'상의 인증서, 회사에 등록된 이용자번호, 이용자의
                      생체정보, 이상의 수단이나 정보를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서 정하고 있는 것을 말합니다.</li>
                    <li>'거래지시'라 함은 이용자가 본 약관에 의하여 체결되는 전자금융거래계약에 따라 회사에 대하여 전자금융거래의 처리를 지시하는 것을 말합니다.</li>
                    <li>'오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한 경우를 말합니다.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="checks my-3 pb-2">
              <input type="checkbox"  id="agree3" value="agree3" class="mr-3">
              <label for="agree3">이 약관에 동의합니다.</label>
            </div>
          </div>

          <div class="agreement d-flex justify-content-center">
            <div class="checks align-self-center">
              <div>
                <input type="checkbox"  id="agree4" value="agree4" class="mr-3">
                <label for="agree4">
                  <span class="allagree">이용약관, 전자금융거래 이용약관, 개인정보 수집/이용안내에 모두 동의합니다.</span></label>
              </div>
            </div>
          </div>
          <div class="para-50">
            <div class="d-flex mt-50 justify-content-center responsive-button">
              <button type="button" class="outline-big-btn mr-3">취소</button>
              <button type="button" class="big-btn">가입완료</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
name: "RegisterTerm",
  path: '/register/term',
}
</script>

<style scoped>

</style>

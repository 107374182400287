<template>
  <main class="main100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-xs-12">
          <div class="para-50">
            <div class="login-title">
              <a @click="$router.push({name:'Main'})">
                <img class="img-responsive mb-30" src="/image/bi_pink.png" alt="BI">
              </a>
              <h2>[ <strong>판매자 회원가입</strong> ]</h2>
            </div>
          </div>
          <!-- 탭 -->
          <div class="function-tab">
            <ul>
              <li class="fucus">
                <a @click="$router.push({name:'RegisterBusiness'})">
                  <span>사업자(법인/개인)</span></a>
              </li>
              <li>
                <a @click="$router.push({name:'RegisterSell'})">
                  <span>개인판매자</span></a>
              </li>
            </ul>
          </div>
          <!-- ========= 사업자(법인/개인) ========= -->
          <div class="para-50">
            <div class="function-cont">
              <div class="register-form">
                <label class="col-lg-2 control-label media-px-0">상호명 (법인명)</label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="form-control" type="text" placeholder="상호명을 입력해주세요.">
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">사업자등록번호</label>
                <div class="col-lg-8 col-md-12 media-px-0">
                  <input class="form-control" type="text" placeholder="사업자등록번호를 입력해주세요.">
                </div>
                <div class="col-lg-2 col-md-12 media-px-0 text-right">
                  <button type="button" class="form-btn">인증하기</button>
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">휴대전화번호</label>
                <div class="col-lg-8 col-md-12 media-px-0">
                  <input class="form-control" type="text" placeholder="휴대전화번호를 입력해주세요.">
                </div>
                <div class="col-lg-2 col-md-12 media-px-0 text-right">
                  <button type="button" class="form-btn">인증번호 발송</button>
                </div>
              </div>

              <div class="register-form">
                <div class="col-lg-2 register-form-title media-px-0">가입비</div>
                <div class="col-lg-10 media-px-0">
                                <span class="register-fee">
                                    <b class="dark-gray02">120,000원 &rarr;</b>
                                    <em>0 원 (무료이벤트)</em>
                                </span>
                </div>
              </div>

              <div class="register-form">
                <div class="col-lg-2 register-form-title media-px-0">사업자 구분</div>
                <div class="col-lg-10 d-flex media-px-0">
                  <div class="radios mr-4">
                    <input type="radio" id="corp" value="corp" name="buisness">
                    <label for="corp">법인</label>
                  </div>
                  <div class="radios">
                    <input type="radio" id="person" value="person" name="buisness">
                    <label for="person">개인</label>
                  </div>
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">업태 / 업종</label>
                <div class="col-lg-5 media-px-0">
                  <input class="form-control" type="text" placeholder="업태를 입력해주세요.">
                </div>
                <div class="col-lg-5 media-px-0">
                  <input class="form-control responsive-mt10" type="text" placeholder="업종을 입력해주세요.">
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 control-label media-px-0">대표자 성함
                  <em>*</em>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="form-control" type="text" placeholder="대표자 성함">
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">아이디
                  <em>*</em>
                </label>
                <div class="col-lg-8 col-md-12 media-px-0">
                  <input class="form-control" type="text" placeholder="4자리 이상의 영문자, 숫자만 가능 (첫 글자는 영문)">
                </div>
                <div class="col-lg-2 col-md-12 media-px-0 text-right">
                  <button type="button" class="form-btn">아이디 중복 확인</button>
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 control-label media-px-0">비밀번호
                  <em>*</em>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="form-control" type="password" placeholder="8자리 숫자, 영문자 혼합만 가능">
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 control-label media-px-0">비밀번호 확인 <em>*</em>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="form-control" type="password" placeholder="비밀번호 확인">
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 control-label media-px-0">판매자명
                  <em>*</em>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="form-control" type="text" placeholder="8자리 숫자, 영문자 혼합만 가능">
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">이메일
                  <em>*</em>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <div class="inline-flex">
                    <input type="email">
                    <div class="py-2">
                      <span class="my-3 px-3">@</span>
                    </div>
                    <input type="text" class="mr-2" placeholder="직접입력">
                    <div class="selectwrap responsive-mt10">
                      <select></select>
                    </div>
                  </div>
                  <p class="register-inf d-block my-2">결제나라 관련소식을 받으실 이메일 주소를 입력해 주세요.</p>
                </div>
              </div>

              <div class="register-form">
                <div class="col-lg-2 register-form-title media-px-0">서비스 구분</div>
                <div class="col-lg-10 media-px-0">
                  <div class="selectwrap" style="width: 40%;">
                    <select></select>
                  </div>
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">주소
                  <em>*</em>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <div class="inline-flex">
                    <button type="button" class="form-btn address-btn">주소찾기</button>
                    <div class="form-control input-postcode">
                      <input type="text" placeholder="우편번호">
                    </div>
                    <div class="form-control input-address">
                      <input class="responsive-mt10" type="text" placeholder="주소">
                    </div>
                  </div>
                  <div class="form-control">
                    <input class="d-block mt-2" type="text" placeholder="상세주소 입력">
                  </div>
                </div>
              </div>

              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">홈페이지
                  <small>(선택)</small>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="d-block" type="text" placeholder="http:// 주소를 입력해주세요.">
                  <p class="register-inf d-block my-2">운영하고 계시는 홈페이지를 입력해 주세요.</p>
                </div>
              </div>
              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">정산은행
                  <small>(선택)</small>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="d-block" type="text" placeholder="정산은행을 입력해주세요.">
                </div>
              </div>
              <div class="register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">계좌번호
                  <small>(선택)</small>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="d-block" type="text" placeholder="계좌번호를 입력해주세요.">
                </div>
              </div>
              <div class="register-form last-register-form">
                <label class="col-lg-2 col-md-12 control-label media-px-0">예금주
                  <small>(선택)</small>
                </label>
                <div class="col-lg-10 col-md-12 media-px-0">
                  <input class="d-block" type="text" placeholder="예금주를 입력해주세요.">
                </div>
              </div>
            </div>
          </div>
          <!-- ========= 사업자(법인/개인) 여기까지 ========= -->
          <div class="d-flex justify-content-center responsive-button">
            <button type="button" class="outline-big-btn mr-3">취소</button>
            <button type="button" class="big-btn">가입완료</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
name: "RegisterBusiness",
  path: '/register/business',
}
</script>

<style scoped>

</style>

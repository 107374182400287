<template>
  <main class="main">
    <!-- ======= section1 Main Slide ======= -->
    <section class="section1">
      <h2 class="hide">페이허브 가맹점 솔루션 슬라이드 배너</h2>
      <div class="slide">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide ss1">
              <div class="container slider-container">
                <div class="slide-contents h-100">
                  <div class="main-tit">
                    <h2>결제통합 솔루션<br>ALL IN ONE, 결제나라</h2>
                    <p>
                      앱을 이용한 페이허브, 카드, 카톡 결제 및<br>다양한 부가 서비스까지
                    </p>
                    <button class="btn-appdown">
                      <a href="" class="f-tran">앱 다운로드</a>
                    </button>
                  </div>
                  <div class="main-img">
                    <img src="/image/main-img4.png" alt="결제통합 솔루션 ALL IN ONE, 결제나라">
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide ss2">
              <div class="container slider-container">
                <div class="slide-contents h-100">
                  <div class="main-tit">
                    <h2>월세, 보증금 등<br>신용카드 선결제 서비스</h2>
                    <p>
                      주택,상가,사무실,인테리어 등 계약서만 있다면<br>카드결제가능, 무이자 할부 지원
                    </p>
                    <button class="btn-appdown">
                      <a @click="$router.push({name:'ServiceMonthly'})" class="f-tran">월세 결제 서비스</a>
                    </button>
                  </div>
                  <div class="main-img">
                    <img src="/image/main-img2.png" alt="월세, 보증금 등 신용카드 선결제 서비스">
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide ss3">
              <div class="container slider-container">
                <div class="slide-contents h-100">
                  <div class="main-tit">
                    <h2>신용카드로<br>배달대행 퀵비 충전!</h2>
                    <p>
                      언제 어디서나 등록된 신용카드로<br>빠른 퀵비 충전 가능
                    </p>
                    <button class="btn-appdown">
                      <a @click="$router.push({name:'ServiceQuick'})" class="f-tran">퀵비 충전 서비스</a>
                    </button>
                  </div>
                  <div class="main-img">
                    <img src="/image/main-img3.png" alt="신용카드로 배달대행 퀵비 충전!">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 Fuction ======= -->
    <section class="section section2">
      <div class="container">
        <h2 class="sub-tit">기능설명</h2>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4 py-3">
            <div class="item">
              <i class="sms sprites">SMS결제</i>
              <p class="item-keyword">
                다수의 고객에게<br><strong>SMS결제 요청 가능</strong>
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 py-3">
            <div class="item">
              <i class="manual sprites">수기결제</i>
              <p class="item-keyword">
                단말기 없이 PC와 모바일에서<br><strong>카드정보만으로 간편 수기결제</strong>
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 py-3">
            <div class="item">
              <i class="qrcode sprites">QR코드 결제</i>
              <p class="item-keyword">
                카메라만 있다면 어디서든<br><strong>QR코드 결제완료</strong>
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 py-3">
            <div class="item">
              <i class="payments sprites">쇼핑몰 지원</i>
              <p class="item-keyword">
                나만의 쇼핑몰을~!<br><strong>쇼핑몰 지원 예정</strong>
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 py-3">
            <div class="item">
              <i class="auto sprites">자동충전 결제</i>
              <p class="item-keyword">
                신용카드로 간단하게<br><strong>월세 충전후 자동결제</strong>
              </p>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 py-3">
            <div class="item">
              <i class="delivery sprites">배달비 충전 결제</i>
              <p class="item-keyword">
                카드결제,무료 계산서 발행 등<br><strong>배달비 충전 결제</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section2 -->
    <!-- ======= section3 Advantages ======= -->
    <section class="section3">
      <h2 class="hide">특장점</h2>
      <article class="advantages bg-gray">
        <div class="shadow-bg"></div>
        <div class="container">
          <div class="row relative">
            <div class="col-sm-12 col-md-6">
              <div class="advantages-txt">
                <span>01</span>
                <h3>신용카드로 퀵비충전<br>
                  <em>현금 유동성은 UP</em></h3>
                <p>
                  언제든 필요한 만큼 간편한 카드 결제 서비스
                </p>
              </div>
            </div>
            <div class="advantages-img-r col-sm-12 col-md-6">
              <div class="advantages-img">
                <img src="/image/advantages_1.png" alt="신용카드로 퀵비충전 현금 유동성은 UP">
              </div>
            </div>
          </div>
        </div>
      </article>
      <article class="advantages bg-white">
        <div class="container">
          <div class="row relative advant-order">
            <div class="advantages-img-r col-sm-12 col-md-6">
              <div class="advantages-img">
                <img src="/image/advantages_2.png" alt="월세/관리비/보증금 등 신용카드 결제 서비스">
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="advantages-txt">
                <span>02</span>
                <h3>월세/관리비/보증금 등<br>
                  <em>신용카드 결제 서비스</em></h3>
                <p>
                  별도 임대인 동의없이 카드 결제 가능<br>
                  무이자 할부로 부담은 DOWN<br>
                  (수수료 4% - 부가세 미포함)
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article class="advantages bg-gray">
        <div class="shadow-bg" style="transform: matrix(1,0,0,-1,0,0); top: 0;"></div>
        <div class="container">
          <div class="row relative">
            <div class="col-sm-12 col-md-6">
              <div class="advantages-txt">
                <span>03</span>
                <h3><em>매출 발생 후 다음날 정산되는</em><br>
                  선정산 서비스</h3>
                <p>
                  페이허브PG, 카톡결제, 수기결제, 단말기 결제건
                </p>
              </div>
            </div>
            <div class="advantages-img-r col-sm-12 col-md-6">
              <div class="advantages-img">
                <img src="/image/advantages_3.png" alt="매출 발생 후 다음날 정산되는 선정산 서비스">
              </div>
            </div>
          </div>
        </div>
      </article>
    </section><!-- End section3 -->
    <!-- ======= section4 Calculate ======= -->
    <section class="section section4 bg-pink">
      <div class="container">
        <h2 class="sub-tit">결제수수료(부가세별도) / 정산안내
          <p>결제 수수료 외 어떠한 추가비용도 없습니다.</p>
        </h2>
        <picture>
          <source srcset="/image/calculate3.png" media="(max-width: 768px)">
          <source srcset="/image/calculate2.png" media="(max-width: 992px)">
          <img src="/image/calculate1.png" alt="결제수수료 외 어떠한 추가비용도 없습니다.">
        </picture>

      </div>
    </section><!-- End section4 -->
    <!-- ======= section5 Information ======= -->
    <section class="section section5">
      <div class="container">
        <h2 class="sub-tit">이용안내</h2>
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="infor-img">
              <img src="/image/information-img.png" alt="이용안내 이미지">
            </div>
          </div>
          <div class="col-sm-12 col-md-7 col-lg-8">
            <div class="infor-box">
              <div class="infor-txt">
                <span>총판/판매점 계약 안내<a href="" class="more">자세히 보기<i class="icon-arrow-right"></i></a></span>
                <p>결제가 난 뒤 계약서류가 완료되면 정산이 시작됩니다. (이메일 접수)</p>
              </div>
            </div>
            <div class="infor-box">
              <div class="infor-txt">
                <span>무료가입/즉시결제<a href="" class="more">자세히 보기<i class="icon-arrow-right"></i></a></span>
                <p>간편 무료 가입 후 즉시 결제받기가 가능합니다.</p>
              </div>
            </div>
            <div class="infor-box">
              <div class="infor-txt">
                <span>보증보험 안내<a href="" class="more">자세히 보기<i class="icon-arrow-right"></i></a></span>
                <p>건당 결제금액 10만원 이상 or 월매출 300만원 이상인 경우
                  보증보험 가입을 요청 드릴 수 있습니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section5 -->
  </main>
</template>

<script>
export default {
  name: "Main",
  path: '/',
  mounted() {
    new window.Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination'
      },
      autoplay: {
        delay: 2000,
      },
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
}
</script>

<style scoped>
</style>

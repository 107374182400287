<template>
  <footer class="footer">
    <div class="container">
      <h1>
        <a href="">페이허브 가맹점 솔루션 결제나라</a>
      </h1>
      <div class="footer-box footer-company">
        <p>
          <span>(주)페이허브</span>
          <span class="bar">대표 : 이정환</span>
        </p>
        <p class="m-block">
          <span>서울특별시 송파구 오금로35길16, 2층(오금동,신신빌딩)</span>
        </p>
        <p class="m-block">
          <span>사업자등록번호 : 673-86-01311</span>
          <span class="bar">대표번호 : 1661-0447</span>
          <span class="bar">FAX : 02-6305-0445</span>
        </p>
        <p class="m-block">
          <span>서비스 계약 및 제휴 문의 : <a href="mailto:cs@payhub.co.kr">cs@payhub.co.kr</a></span>
          <span class="bar">기술 지원 : <a href="mailto:tech@payhub.co.kr">tech@payhub.co.kr</a></span>
        </p>
      </div>
      <div class="footer-box">
        <p class="footer-bold f-tran">전자지급결제대행업 : 02-004-00162</p>
      </div>
      <div class="footer-box footer-terms">
        <p class="footer-bold f-tran">페이허브 기본약관</p>
        <p class="pt-3">
          <span><a @click="$router.push({name:'Term1'})">서비스 이용약관</a></span>
          <span class="bar"><a @click="$router.push({name:'Term2'})">개인정보 처리방침</a></span>
          <span class="bar"><a @click="$router.push({name:'Term3'})">전자금융거래 기본약관</a></span>
        </p>
      </div>
      <div class="footer-box">
        <p class="footer-bold">copyright ⓒ2024 PAYHUB. All Rights Reserved.</p>
      </div>
    </div>
    <div class="gotop" @click="goTop"></div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
  },
  methods: {
    goTop() {
      window.$('html, body').animate({
        scrollTop: 0
      });
    }
  }
}
</script>

<style scoped>

</style>

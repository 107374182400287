<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 guide section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'Guide1'})"><span>비용안내</span></a></li>
            <li><a @click="$router.push({name:'Guide2'})"><span>가입·결제받기</span></a></li>
            <li><a @click="$router.push({name:'Guide3'})"><span>구비서류·심사</span></a></li>
            <li><a @click="$router.push({name:'Guide4'})"><span>보증보험</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'Guide5'})"><span>정산안내</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 guid5 ======= -->
    <section class="guide section2">
      <div class="container guid">
        <!-- 정산안내 -->
        <div class="heading-tit">
          <h2>정산안내</h2>
          <p>정산요건 및 정산주기는 다음과 같습니다.</p>
        </div>
        <div class="panel-block">
          <div class="panel-block-area">
            <p class="check">기본 정산주기는 D+5일이며, 빠른 정산 신청이 가능합니다. 첫 정산 시에 계약서류 제출이 필요하고, 보증보험을 요청 드리는 경우 보험증권이 발행된 다음 평일에 정산됩니다.</p>
            <p class="check">서류접수 후 심사완료까지 영업일로 3~5일 정도 소요될 수 있으며, 서류팀에서 순차적으로 심사 후 유선으로 연락 드립니다.
              결제 받으시고 1~2일 내에 서류를 보내주시는 게 가장 좋습니다.</p>
          </div>
        </div>
        <!-- 정산대상 -->
        <div class="panel">
          <h3>정산대상</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-desc col-12">
                <ul class="ex-li">
                  <li>신청 구비서류가 모두 제출되고, 간단한 업종심사를 거친 가맹점에 한해 정산이 진행됩니다.</li>
                  <li>심사 과정에서 보증보험이 요청된 경우 보험증권이 발행된 다음 평일에 정산됩니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 정산주기 -->
        <div class="panel">
          <h3>정산주기</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-tit pb-3 pb-md-0 col-sm-12 col-md-2">
                기본 정산 주기
              </div>
              <div class="panel-list-desc pb-3 pb-md-0 col-sm-12 col-md-5">
                <p class="pb-2 d-block"><span>D+1일 (익일정산)</span></p>
                <p>
                  결제일 다음 평일에 정산 입금
                </p>
              </div>
              <div class="panel-list-desc col-sm-12 col-md-5">
                <p class="pb-2 d-block"><span>D+5일 (5일정산)</span></p>
                <p class="d-block">
                  첫정산 이후에는 결제일 이후 5영업일째 되는 평일에,
                </p>
                <p class="d-block">
                  등록된 계좌로 결제수수료를 제외한 금액이 자동으로 정산 입금
                </p>
              </div>
            </div>
          </div>
          <table class="tg">
            <thead>
            <tr>
              <th class="tg-baqh" style="border-left: none;"><i class="f-tran">일</i></th>
              <th class="tg-baqh"><i class="f-tran">월</i></th>
              <th class="tg-baqh"><i class="f-tran">화</i></th>
              <th class="tg-4kwo"><i class="f-tran">수</i></th>
              <th class="tg-baqh"><i class="f-tran">목</i></th>
              <th class="tg-baqh"><i class="f-tran">금</i></th>
              <th class="tg-baqh" style="border-right: none;"><i class="f-tran">토</i></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="tg-uqo3" style="border-left: none;"></td>
              <td class="tg-uqo3"></td>
              <td class="tg-uqo3"></td>
              <td class="tg-t0n7"><i class="f-tran">결제일</i></td>
              <td class="tg-baqh"><i class="f-tran">익일 정산<br>D+1일</i></td>
              <td class="tg-baqh"><i class="f-tran">D+2일</i></td>
              <td class="tg-amwm" style="border-right: none;"><i class="f-tran">휴무일</i></td>
            </tr>
            <tr>
              <td class="tg-amwm" style="border-left: none;"><i class="f-tran">휴무일</i></td>
              <td class="tg-baqh"><i class="f-tran">3일 정산<br>D+3일</i></td>
              <td class="tg-baqh"><i class="f-tran">D+4일</i></td>
              <td class="tg-t0n7"><i class="f-tran">5일 정산</i></td>
              <td class="tg-uqo3"></td>
              <td class="tg-uqo3"></td>
              <td class="tg-uqo3" style="border-right: none;"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- 정산시간 -->
        <div class="panel">
          <h3>정산시간</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-desc col-12">
                <p>
                  연동 매뉴얼을 참고해 쇼핑몰/사이트/서비스에 쉽게 결제연동 가능
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 세금계산서 -->
        <div class="panel">
          <h3>세금계산서</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-desc col-12">
                <ul class="ex-li">
                  <li>매월 1~2일에 전월분 결제수수료에 대한 세금계산서를 페이허브에서 가맹점으로 일괄 발행해 드립니다.</li>
                  <li>해당 기간이 주말 또는 공휴일인 경우 다음 평일에 발행됩니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End guid5 -->
  </main>
</template>

<script>
export default {
name: "Guide5",
  path: '/guide/5',
}
</script>

<style scoped>

</style>

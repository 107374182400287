<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 support  section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'Faq'})"><span>자주묻는 질문</span></a></li>
            <li><a @click="$router.push({name:'Notice'})"><span>공지사항</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'InquiryList'})"><span>문의사항</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 Lnquiry-search ======= -->
    <section class="support section2">
      <div class="container">
        <div class="heading-tit d-notice">
          <h2>문의사항</h2>
          <div class="search-input">
            <input type="text" placeholder="검색어를 입력하세요.">
            <button type="button" class="btn-search">
              <img src="/image/icon_search.svg" alt="검색">
            </button>
          </div>
        </div>
        <div class="support-content">
          <div class="support-tab">
            <ul>
              <li onclick="location.href='inquiry_seller_write.html'">판매자 회원</li>
              <li class="focus" onclick="location.href='inquiry_buyer_write.html'">결제고객(구매자)</li>
            </ul>
          </div>
          <div class="support-info">
            <h5>문의 전 안내사항</h5>
            <div class="support-box">
              <p class="info mb-0">
                <strong>Q. 결제 내역 확인이 필요하세요?</strong>
              </p>
              <p class="info mb-0">
                <strong>Q. 판매자와 연락이 안 됩니다. 취소하고 싶은데 민원을 어떻게 접수하죠?</strong>
              </p>
              <p class="info">
                <strong>Q. 판매자와 결제 취소에 대한 분쟁이 있습니다. 민원을 어떻게 접수하죠?</strong>
                <a href="" class="point-color underline">결제 내역 조회</a> 메뉴에서 내역 조회가 가능하며 결제 내역 조회 결과 화면에 표시되는 <b class="text-red">[민원접수] 기능으로 온라인 민원신청이 가능</b>합니다.<br>
                온라인 민원 신청 시 결제나라 접수와 판매점에 대한 통보가 자동으로 이뤄지며, 결제나라 담당자가 지속 체크하여 민원 해결을 적극적으로 돕습니다.
              </p>
            </div>
            <div>
              <div class="form">
                <div class="container">
                  <div class="form-item row">
                    <label class="label col-3">문의 유형</label>
                    <div class="col-9 selectwrap pl-0 pr-0">
                      <select>
                        <option value="문의 유형을 선택하세요. (일반문의 / 연동문의)">문의 유형을 선택하세요. (일반문의 / 연동문의)</option>
                        <option value="일반문의">일반문의</option>
                        <option value="연동문의">연동문의</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">제목</label>
                    <input class="input-inquiry col-9" type="text" placeholder="제목을 입력해주세요.">
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">내용</label>
                    <textarea class="textarea-inquiry col-9" placeholder="내용을 입력해주세요."></textarea>
                  </div>
                  <div class="form-item row">
                    <div class="label col-3">파일첨부</div>
                    <div class="filebox col-9 pl-0 pr-0 d-flex">
                      <input class="upload-name" placeholder="파일을 첨부해주세요. / 최대 2MB">
                      <label for="file">첨부파일</label>
                      <input type="file" id="file">
                    </div>
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">작성자</label>
                    <input class="input-inquiry col-9" type="text" placeholder="아름을 입력해주세요.">
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">비밀번호</label>
                    <input class="input-inquiry col-9" type="password" placeholder="비밀번호를 입력해주세요.">
                  </div>
                  <div class="form-item row">
                    <label class="label col-3">자동등록방지</label>
                    <div class="input-inquiry-capcha col-9">
                      <div class="capcha-area">
                        <div class="capcha-img">
                          <img src="/image/capcha-img.png" alt="자동등록방지 이미지">
                        </div>
                        <div class="capcha-con">
                          <input class="capcha-input" type="text" placeholder="보안문자를 입력해주세요.">
                          <button type="button" class="button-refresh">
                            <i class="sprites refresh"></i><span>새로고침</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-btn list-btn-box justify-content-center">
            <div class="button-con">
              <button type="button" class="middle-btn">등록</button>
              <button type="button" class="outline-middle-btn ml-3">취소</button>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End Lnquiry-search -->
  </main>
</template>

<script>
export default {
name: "InquiryBuyerWrite",
  path: '/support/inquiry/buyer/write',
}
</script>

<style scoped>

</style>

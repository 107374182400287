<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 support section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'Faq'})"><span>자주묻는 질문</span></a></li>
            <li><a @click="$router.push({name:'Notice'})"><span>공지사항</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'InquiryList'})"><span>문의사항</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 Lnquiry-search ======= -->
    <section class="support section2">
      <div class="container">
        <div class="heading-tit d-notice">
          <h2>문의사항</h2>
          <div class="search-input">
            <input type="text" placeholder="검색어를 입력하세요.">
            <button type="button" class="btn-search">
              <img src="/image/icon_search.svg" alt="검색">
            </button>
          </div>
        </div>
        <div class="support-content">
          <div class="notice-view">
            <div class="notice-view-title">
              <h5>페이허브 업데이트 안내</h5>
              <div class="notice-view-date d-flex justify-content-between">
                <span>2021.02.15</span>
                <p class="txt-area">
                  <span>신규문의</span>
                  <span class="g-bar">1,000</span>
                </p>
              </div>
            </div>
            <p class="notice-view-file">
              <i class="sprites folder"></i><em>12345678_20211010.jpg</em>
            </p>
            <p class="notice-view-content">
              안녕하세요 결제나라입니다.<br>
              2021년 10월 15일(화) 오전 06:00 ~ 08:00 동안 서버 작업이 진행됩니다.<br>
              작업 시간 동안 순단 발생 가능성 있으니 업무에 참고 부탁 드리겠습니다.<br><br>

              - 작업시간 : 2021년 10월 16일(화) 오전 06:00 ~ 08:00 (2시간)<br>
              - 작업 내용 : 페이앱 결제 과세유형 선택 기능 추가<br><br>

              안정적인 서비스 제공을 위하여 서버 작업이 진행될 예정이오니<br>
              서비스 이용에 다소 불편함이 있는 점 깊은 양해 부탁 드립니다.<br><br>

              감사합니다.<br><br>

              ※ 작업시간 지연될수있는점 양해부탁드립니다.
            </p>
            <div class="notice-view-answer">
              <b>답변</b>
              <p>
                안녕하세요. 결제나라 입니다.안정적인 서비스 제공을 위하여 서버 작업이 진행될 예정이오니<br>
                서비스 이용에 다소 불편함이 있는 점 깊은 양해 부탁 드립니다.<br><br>
                감사합니다.
              </p>
            </div>
          </div>
          <div class="list-btn list-btn-box">
            <div class="button-con">
              <button type="button" class="middle-btn">삭제</button>
              <button type="button" class="middle-btn ml-3">수정</button>
            </div>
            <button type="button" class="outline-middle-btn">목록</button>
          </div>
        </div>
      </div>
    </section><!-- End Lnquiry-search -->
  </main>
</template>

<script>
export default {
name: "InquiryView",
  path: '/support/inquiry/view/:idx',
}
</script>

<style scoped>

</style>

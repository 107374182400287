<template>
  <main class="sub-main service">
    <!-- ======= section1 function ======= -->
    <section class="section-80 service section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li class="fucus"><a @click="$router.push({name:'ServiceQuick'})"><span>퀵비충전</span></a></li>
            <li><a @click="$router.push({name:'ServiceMonthly'})"><span>월세결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceQr'})"><span>QR·카톡·수기결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceShop'})"><span>쇼핑몰 지원</span></a></li>
            <li><a @click="$router.push({name:'ServiceSales'})"><span>매출 알림 서비스</span></a></li>
          </ul>
        </div>
        <div class="function-cont">
          <div class="row">
            <div class="fun-tit">
              <h2><em>배달대행 퀵비 충전을 신용카드로</em><br>퀵비충전 서비스</h2>
              <hr>
              <p>현금으로 충전하던 퀵비를 신용카드로 충전하세요.<br>
                퀵비 결제 시 다음날에 배대사 계좌로 수수료를 제외한 금액을 입금해드립니다.</p>
            </div>
            <div class="fun-img">
              <img src="/image/service_quick.png" alt="퀵비충전 서비스">
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 keyword ======= -->
    <section class="section-80 service section2 section-keyword bg-pink">
      <h2 class="hide">퀵비·월세결제 장점</h2>
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="pg sprites">국내 최저 수수료</i>
              <h4>국내 최저 수수료</h4>
              <p>
                퀵비 충전 서비스 이용료는 0%<small>(부가세별도)</small>로 업계 최저에 도전합니다.<br><small>(10만원 결제 시 0원 제한 후 다음날 충전)</small>
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="pay sprites">모든 신용카드 결제</i>
              <h4>모든 신용카드 결제</h4>
              <p>
                최초 카드 등록 후, 다음부터는 간편결제로 바로바로 결제가능합니다.
              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 py-3">
            <div class="service-item">
              <i class="history sprites">보기 편한 내역관리</i>
              <h4>보기 편한 내역관리</h4>
              <p>
                결제부터 정산까지 간편하게 내역을 확인하실 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section2 -->
    <!-- ======= section3 screen ======= -->
    <section class="section-80 service section3 quick-section">
      <div class="container">
        <h2 class="sub-tit">퀵비충전 화면</h2>
        <div class="row">
          <div class="screen-box">
            <span class="screen-tit">01. 퀵비 충전</span>
            <div class="web_sc">
              <ul>
                <li class="col-3"><img src="/image/screen_quick_card_1.png" alt="퀵비충전신용카드선택"></li>
                <li class="col-3"><img src="/image/screen_quick_card_2.png" alt="퀵비충전"></li>
                <li class="col-3"><img src="/image/screen_quick_card_3.png" alt="핀번호입력"></li>
                <li class="col-3"><img src="/image/screen_quick_card_4.png" alt="충전완료"></li>
              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_1 sw_quick_1">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_quick_card_1.png" alt="퀵비충전신용카드선택">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_quick_card_2.png" alt="퀵비충전">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_quick_card_3.png" alt="핀번호입력">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_quick_card_4.png" alt="충전완료">
                </div>

              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
          <div class="screen-box screen-box2">
            <span class="screen-tit">02. 퀵비 내역</span>
            <div class="web_sc">
              <ul>
                <li class="col-3"><img src="/image/screen_quick_card_5.png" alt="퀵비결제내역"></li>
                <li class="col-3"><img src="/image/screen_quick_card_6.png" alt="조회월선택"></li>
                <li class="col-3"><img src="/image/screen_quick_card_7.png" alt="상세결제내역"></li>
              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_2 sw_quick_2">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_quick_card_5.png" alt="퀵비결제내역">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_quick_card_6.png" alt="조회월선택">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <img src="/image/screen_quick_card_7.png" alt="상세결제내역">
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>

        </div>
      </div>
    </section><!-- End section3 -->
  </main>
</template>

<script>
export default {
  name: "ServiceQuick",
  path: '/service/quick',
  mounted() {

    // 월세결제 화면
    new window.Swiper('.sw_quick_1', {
      loop: false,
      navigation: {
        nextEl: ".sw_quick_1 .swiper-button-next",
        prevEl: ".sw_quick_1 .swiper-button-prev",
      },
    });
    new window.Swiper('.sw_quick_2', {
      loop: false,
      navigation: {
        nextEl: ".sw_quick_2 .swiper-button-next",
        prevEl: ".sw_quick_2 .swiper-button-prev",
      },
    });
  }
}
</script>

<style scoped>

</style>

<template>
  <main class="sub-main service">
    <!-- ======= section1 function ======= -->
    <section class="section-80 service section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'ServiceQuick'})"><span>퀵비충전</span></a></li>
            <li><a @click="$router.push({name:'ServiceMonthly'})"><span>월세결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceQr'})"><span>QR·카톡·수기결제</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'ServiceShop'})"><span>쇼핑몰 지원</span></a></li>
            <li><a @click="$router.push({name:'ServiceSales'})"><span>매출 알림 서비스</span></a></li>
          </ul>
        </div>
        <div class="function-cont">
          <div class="row">
            <div class="fun-tit">
              <h2><em>이제, 나만의 쇼핑몰을~!</em><br>쇼핑몰 지원 예정</h2>
              <hr>
              <p>결제나라 앱 가입으로 나만의 쇼핑몰을 만들어 보세요~!<br>
                쇼핑몰 지원은 추후 예정입니다.</p>
            </div>
            <div class="fun-img">
              <img src="/image/service_shop.png" alt="쇼핑몰지원">
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 screen ======= -->
    <section class="section-80 service section2 bg-pink">
      <div class="container">

        <h2 class="sub-tit">쇼핑물 지원 화면</h2>
        <div class="row">
          <div class="ready">
            <p>
              <span>쇼핑몰 지원 화면 준비중입니다.</span>
              <em>빠른 시일 내에 지원해 드릴 수 있도록 최선을 다하겠습니다.</em>
            </p>
          </div>
        </div>
      </div>
    </section><!-- End section2 -->
  </main>
</template>

<script>
export default {
  name: "ServiceShop",
  path: '/service/shop',
  mounted() {

    new window.Swiper('.sw_simple_1', {
      loop: false,
      navigation: {
        nextEl: ".sw_simple_1 .swiper-button-next",
        prevEl: ".sw_simple_1 .swiper-button-prev",
      },
    });
  }
}
</script>

<style scoped>

</style>

<template>
  <main class="sub-main service">
    <!-- ======= section1 function ======= -->
    <section class="section-80 service section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'ServiceQuick'})"><span>퀵비충전</span></a></li>
            <li><a @click="$router.push({name:'ServiceMonthly'})"><span>월세결제</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'ServiceQr'})"><span>QR·카톡·수기결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceShop'})"><span>쇼핑몰 지원</span></a></li>
            <li><a @click="$router.push({name:'ServiceSales'})"><span>매출 알림 서비스</span></a></li>
          </ul>
        </div>
        <div class="function-cont">
          <div class="row">
            <div class="fun-tit">
              <h2><em>비대면 결제가 필요할 땐,</em><br>QR/카톡/수기 결제 서비스</h2>
              <hr>
              <p><b>QR 결제 :</b> QR로 스캔하고 카드로 바로 결제<br>
                <b>카톡결제 :</b> 원하는 날짜에 예약하고 결제 링크 통한 쉬운 결제<br>
                <b>수기 결제 :</b> 직접 카드정보 입력하고 빠르게 결제
              </p>
            </div>
            <div class="fun-img">
              <img src="/image/service_qr.png" alt="QR·카톡·수기결제">
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 screen ======= -->
    <section class="section-80 service section2 bg-pink">
      <div class="container">
        <h2 class="sub-tit">QR 결제 화면</h2>
        <div class="row">
          <div class="screen-box">
            <div class="web_sc">
              <ul class="justify-content-between">
                <li class="col-3">
                  <span class="screen-tit pl-0">01. 결제금액 입력</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_1.png" alt="결제금액입력">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">02. QR스캔</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_2.png" alt="QR스캔">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">03. 결제완료</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_3.png" alt="결제완료">
                  </div>
                </li>
              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_1 sw_qr_1">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">01. 결제금액 입력</span>
                  <img src="/image/screen_qr_1.png" alt="결제금액입력">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">02. QR스캔</span>
                  <img src="/image/screen_qr_2.png" alt="QR스캔">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">03. 결제완료</span>
                  <img src="/image/screen_qr_3.png" alt="결제완료">
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section2 -->
    <!-- ======= section3 screen ======= -->
    <section class="section-80 service section3">
      <div class="container">
        <h2 class="sub-tit">카톡 결제 화면</h2>
        <div class="row">
          <div class="screen-box">
            <div class="web_sc">
              <ul class="justify-content-between">
                <li class="col-3">
                  <span class="screen-tit pl-0">01. 기본정보 입력</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_4.png" alt="기본정보입력">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">02. 연락처 입력</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_5.png" alt="연락처입력">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">03. 카톡 발송 완료</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_6.png" alt="카톡발송완료">
                  </div>
                </li>
              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_1 sw_qr_2">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">01. 기본정보 입력</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_4.png" alt="기본정보입력">
                  </div>
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">02. 연락처 입력</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_5.png" alt="연락처입력">
                  </div>
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">03. 카톡 발송 완료</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_6.png" alt="카톡발송완료">
                  </div>
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section3 -->
    <!-- ======= section4 screen ======= -->
    <section class="section-80 service section4 bg-pink">
      <div class="container">
        <h2 class="sub-tit">수기 결제 화면</h2>
        <div class="row">
          <div class="screen-box">
            <div class="web_sc">
              <ul class="justify-content-between">
                <li class="col-3">
                  <span class="screen-tit pl-0">01. 기본정보 입력</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_7.png" alt="기본정보입력">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">02. 카드정보 입력</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_8.png" alt="카드정보입력">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">03. 결제완료</span>
                  <div class="img-box">
                    <img src="/image/screen_qr_9.png" alt="결제완료">
                  </div>
                </li>
              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_1 sw_qr_3">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">01. 기본정보 입력</span>
                  <img src="/image/screen_qr_7.png" alt="기본정보입력">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">02. 카드정보 입력</span>
                  <img src="/image/screen_qr_8.png" alt="카드정보입력">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">03. 결제완료</span>
                  <img src="/image/screen_qr_9.png" alt="결제완료">
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section4 -->
  </main>
</template>

<script>
export default {
  name: "ServiceQr",
  path: '/service/qr',
  mounted() {

    new window.Swiper('.sw_qr_1', {
      loop: false,
      navigation: {
        nextEl: ".sw_qr_1 .swiper-button-next",
        prevEl: ".sw_qr_1 .swiper-button-prev",
      },

    });
    new window.Swiper('.sw_qr_2', {
      loop: false,
      navigation: {
        nextEl: ".sw_qr_2 .swiper-button-next",
        prevEl: ".sw_qr_2 .swiper-button-prev",
      },
    });
    new window.Swiper('.sw_qr_3', {
      loop: false,
      navigation: {
        nextEl: ".sw_qr_3 .swiper-button-next",
        prevEl: ".sw_qr_3 .swiper-button-prev",
      },

    });
  }
}
</script>

<style scoped>

</style>

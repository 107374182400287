import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Main from '@/components/Main'
import About from '@/components/about/About'
import Privacy from '@/components/app_term/Privacy'
import Guide1 from '@/components/guide/Guide1'
import Guide2 from '@/components/guide/Guide2'
import Guide3 from '@/components/guide/Guide3'
import Guide4 from '@/components/guide/Guide4'
import Guide5 from '@/components/guide/Guide5'
import FindId from '@/components/login/FindId'
import FindPw from '@/components/login/FindPw'
import LoginSeller from '@/components/login/LoginSeller'
import LoginSub from '@/components/login/LoginSub'
import RegisterBusiness from '@/components/register/RegisterBusiness'
import RegisterSell from '@/components/register/RegisterSell'
import RegisterTerm from '@/components/register/RegisterTerm'
import ServiceShop from '@/components/service/ServiceShop'
import ServiceMonthly from '@/components/service/ServiceMonthly'
import ServiceQr from '@/components/service/ServiceQr'
import ServiceQuick from '@/components/service/ServiceQuick'
import ServiceSales from '@/components/service/ServiceSales'
import Faq from '@/components/support/Faq'
import InquiryBuyerWrite from '@/components/support/InquiryBuyerWrite'
import InquiryList from '@/components/support/InquiryList'
import InquirySellerWrite from '@/components/support/InquirySellerWrite'
import InquiryView from '@/components/support/InquiryView'
import Notice from '@/components/support/Notice'
import NoticeView from '@/components/support/NoticeView'
import Term1 from '@/components/term/Term1'
import Term2 from '@/components/term/Term2'
import Term3 from '@/components/term/Term3'

export default new Router({
	routes: [
		{
			path: '/',
			name: 'Main',
			component: Main,
		},
		{
			path: '/about/about',
			name: 'About',
			component: About,
		},
		{
			path: '/app_term/privacy',
			name: 'Privacy',
			component: Privacy,
		},
		{
			path: '/guide/1',
			name: 'Guide1',
			component: Guide1,
		},
		{
			path: '/guide/2',
			name: 'Guide2',
			component: Guide2,
		},
		{
			path: '/guide/3',
			name: 'Guide3',
			component: Guide3,
		},
		{
			path: '/guide/4',
			name: 'Guide4',
			component: Guide4,
		},
		{
			path: '/guide/5',
			name: 'Guide5',
			component: Guide5,
		},
		{
			path: '/find/id',
			name: 'FindId',
			component: FindId,
		},
		{
			path: '/find/password',
			name: 'FindPw',
			component: FindPw,
		},
		{
			path: '/login/seller',
			name: 'LoginSeller',
			component: LoginSeller,
		},
		{
			path: '/login/sub',
			name: 'LoginSub',
			component: LoginSub,
		},
		{
			path: '/register/business',
			name: 'RegisterBusiness',
			component: RegisterBusiness,
		},
		{
			path: '/register/sell',
			name: 'RegisterSell',
			component: RegisterSell,
		},
		{
			path: '/register/term',
			name: 'RegisterTerm',
			component: RegisterTerm,
		},
		{
			path: '/service/shop',
			name: 'ServiceShop',
			component: ServiceShop,
		},
		{
			path: '/service/monthly',
			name: 'ServiceMonthly',
			component: ServiceMonthly,
		},
		{
			path: '/service/qr',
			name: 'ServiceQr',
			component: ServiceQr,
		},
		{
			path: '/service/quick',
			name: 'ServiceQuick',
			component: ServiceQuick,
		},
		{
			path: '/service/sales',
			name: 'ServiceSales',
			component: ServiceSales,
		},
		{
			path: '/support/faq',
			name: 'Faq',
			component: Faq,
		},
		{
			path: '/support/inquiry/buyer/write',
			name: 'InquiryBuyerWrite',
			component: InquiryBuyerWrite,
		},
		{
			path: '/support/inquiries',
			name: 'InquiryList',
			component: InquiryList,
		},
		{
			path: '/support/inquiry/seller/write',
			name: 'InquirySellerWrite',
			component: InquirySellerWrite,
		},
		{
			path: '/support/inquiry/view/:idx',
			name: 'InquiryView',
			component: InquiryView,
		},
		{
			path: '/support/notices',
			name: 'Notice',
			component: Notice,
		},
		{
			path: '/support/notice/:idx',
			name: 'NoticeView',
			component: NoticeView,
		},
		{
			path: '/term/1',
			name: 'Term1',
			component: Term1,
		},
		{
			path: '/term/2',
			name: 'Term2',
			component: Term2,
		},
		{
			path: '/term/3',
			name: 'Term3',
			component: Term3,
		},
	]
})

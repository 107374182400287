<template>
  <div>
    <div class="modal_popup" v-show="showModal">
      <div class="modal_wrap" v-show="showModal">
        <div class="modal_header">
          <h3>등록 심사 업종 기준</h3>
          <button class="modal_popup_close icon-close" @click="showModal = false"></button>
        </div>
        <div class="modal_cont">
          <div class="text_box">
            <h4>불가업종</h4>
            <p>경마, 경륜, 대출, 도박/성인사이트(음란물), 기타 미풍양속을 저해하는 업종, 선불카드, 순금/순은, 환금성, 의약품, 저가경매, 휴대폰가입을포함한판매</p>
          </div>
          <div class="text_box">
            <h4>리스크업종</h4>
            <p>구매대행, 귀금속(30만원이상), 금연보조제, 전자담배, 다단계, 담배(전자담배, 해외고객대상 판매 가능), 로또번호 생성 서비 스, 명품, 모바일쿠폰/기프티콘, 미곡상(20만원 이상), 부적, 상품권/복권, 성인사이트 컨텐츠, 성인용품 사이트, 소셜커머스, 아이템, 웨하드, 채팅, 총포/도검, 호스팅/광고, 콘도회원권, 인터넷쇼핑몰분양, 공동구매, 건강/다이어트식품, 광고대행</p>
          </div>
          <div class="text_box">
            <h4>유의업종</h4>
            <p>게임, 경매, 군복 및 군장도구, 도수 있는 안경/썬글라스/콘택트 렌즈, 동창회비, 사이버머니(10만원 초과), 소프트웨어, 숙박, 숙박여행, 식물, 신용카드단말기, 애완동물, 온라인강의, 유흥 구인구직, 전자여권수속(ESTA), 전통주, 정기주차권, 정보 서비스, 주류, 중고차, 증명서 발급, 참가비/회비, 티켓발급 (이미용, 교통, 항공), 후원금</p>
          </div>
        </div>
      </div>
    </div>
    <main class="sub-main">
      <!-- ======= section1 tab ======= -->
      <section class="section-80 guide section1">
        <div class="container">
          <div class="function-tab">
            <ul>
              <li><a @click="$router.push({name:'Guide1'})"><span>비용안내</span></a></li>
              <li class="fucus"><a @click="$router.push({name:'Guide2'})"><span>가입·결제받기</span></a></li>
              <li><a @click="$router.push({name:'Guide3'})"><span>구비서류·심사</span></a></li>
              <li><a @click="$router.push({name:'Guide4'})"><span>보증보험</span></a></li>
              <li><a @click="$router.push({name:'Guide5'})"><span>정산안내</span></a></li>
            </ul>
          </div>
        </div>
      </section><!-- End section1 -->
      <!-- ======= section2 guid2 ======= -->
      <section class="guide section2">
        <div class="container guid">
          <!-- 가입 결제 받기 -->
          <div  class="heading-tit">
            <h2>가입·결제받기</h2>
            <p>수수료외에는 어떠한 추가 비용도 발생하지 않습니다.</p>
          </div>
          <div class="panel-block">
            <div class="panel-block-area">
              <p class="check">무료 가입 후 다양한 방법으로 즉시 결제받기가 가능합니다.</p>
            </div>
          </div>
          <!-- 무료가입 -->
          <div class="panel">
            <h3>무료가입</h3>
            <div class="line">
              <div class="panel-list row">
                <div class="panel-list-tit col-12 pb-4">
                  132,000원 등록비 무료 이벤트 중
                </div>
                <div class="panel-list-desc col-12">
                  <p class="pb-4">
                    간편하게 회원가입 하신 후 다양한 혜택과 기능을 평생 무료로 누려보세요.
                  </p>
                  <div class="panel-list-btn">
                    <button class="table-btn">회원가입 하기</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 결제받기 -->
          <div class="panel">
            <h3>결제받기</h3>
            <div class="line">
              <div class="panel-list row">
                <div class="panel-list-tit col-12 pb-4">
                  01. APP으로 이용하기
                </div>
                <div class="panel-list-desc col-12">
                  <p class="pb-4">
                    앱 설치 후 즉시 결제받기 가능
                  </p>
                  <div class="panel-list-btn">
                    <button class="table-btn">
                      <span class="icon-google hidden">구글 스토어</span>
                    </button>
                    <button class="table-btn ">
                      <span class="icon-app hidden">앱 스토어</span>
                    </button>
                  </div>
                </div>
              </div>
              <p class="panel-line"></p>
              <div class="panel-list row">
                <div class="panel-list-tit col-12 pb-4">
                  02. PC버전으로 이용하기
                </div>
                <div class="panel-list-desc col-12">
                  <p class="pb-4">
                    앱과 동일한 계정으로 접속/이용
                  </p>
                  <div class="panel-list-btn">
                    <button class="table-btn">관리자 로그인</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 서류제출 -->
          <div class="panel">
            <h3>서류제출</h3>
            <div class="line">
              <div class="panel-list row">
                <div class="panel-list-desc col-12">
                  <p class="pb-4 d-block">
                    회원가입 시 계약 서류 제출이 필요합니다.
                  </p>
                  <p class="pb-4 text-red d-block">
                    사업자등록증, 대표자신분증, 통장사본
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 업종심사 -->
          <div class="panel">
            <h3>업종심사</h3>
            <div class="line">
              <div class="panel-list row">
                <div class="panel-list-desc col-12">
                  <p class="pb-4 d-block">
                    계약서류 검토와 함께 간단한 업종심사가 이어집니다.
                  </p>
                  <p class="pb-4 text-red d-block">
                    유의업종 및 리스크업종의 경우 결제수수료가 인상되며, 불가업종의 경우 결제가 취소될 수 있습니다.
                  </p>
                  <div class="panel-list-btn">
                    <button class="table-btn" @click="showModal = true">유의 및 불가업종 보기</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 보증보험 -->
          <div class="panel">
            <h3>보증보험</h3>
            <div class="line">
              <div class="panel-list row">
                <div class="panel-list-desc col-12">
                  <p class="pb-4">
                    결제규모와 업종에 따라 보증보험 신청을 요청 드릴 수 있습니다.
                  </p>
                  <div class="panel-list-btn">
                    <button class="table-btn">보증보험 안내 보기</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section><!-- End guid2 -->
    </main>
  </div>
</template>

<script>
export default {
  name: "Guide2",
  path: '/guide/2',
  data() {
    return {
      showModal: false,
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <main class="main100">
    <div class="para-80">
      <div class="container">
        <div class="container-content">
          <div class="terms">
            <!-- 탭 -->
            <div class="function-tab function-tab3 mb-50">
              <ul>
                <li class="fucus">
                  <a @click="$router.push({name:'Term1'})">
                    <span>서비스 이용약관</span></a>
                </li>
                <li>
                  <a @click="$router.push({name:'Term2'})">
                    <span>개인정보처리방침</span></a>
                </li>
                <li>
                  <a @click="$router.push({name:'Term3'})">
                    <span>전자금융거래 이용약관</span></a>
                </li>
              </ul>
            </div>

            <!-- 서비스 이용약관 -->
            <div class="function-cont">
              <h2 class="term-title mt-30 mb-50">서비스 이용약관</h2>
              <div class="terms-content">
                <div class="terms-top-box">
                  <p>결제나라서비스(이하 서비스)에 필요한 사항을 규정한 약관입니다.</p>
                </div>
                <div class="terms-wrap">
                  <h4>제1장 총칙</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제1조 (목적)</h5>
                  <p class="terms-description">이 약관은 “주식회사 페이허브”(이하 “회사”)가 제공하는 서비스의 이용과 관련하여 회사와 “결제나라회원”(이하 “회원”)과의 권리,
                    의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제2조 (정의)</h5>
                  <p class="terms-description">이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                  <ol class="terms-list-nodep">
                    <li>회원이란 서비스 이용약관에 따라 회사와 이용계약을 체결하고 회사에서 운영하는 서비스를 이용할 수 있는 개인이나 사업자를 말합니다.</li>
                    <li>고객이란 회원과 거래하는 거래 상대방을 말합니다.</li>
                    <li>서비스라 함은 회사가 운영하고 제공하는 회원 서비스를 말합니다.</li>
                    <li>"아이디"라 함은 회원의 서비스 로그인과 이용을 위하여 사용되는 휴대전화번호 또는 회원이 지정한 영문숫자조합을 의미합니다.</li>
                    <li>"결제정보"라 함은 서비스 이용 및 결제를 위해 회원이 등록한 카드정보를 말합니다.</li>
                    <li>"전자지급결제대행서비스"라 함은 전자적 방법으로 지급결제정보를 송신 또는 수신하거나 그 대가의 정산을 대행하거나 매개하는 서비스를 말합니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제3조 (약관의 게시와 개정)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 이 약관의 내용을 회원가입 및 결제 화면에 게시합니다.</li>
                    <li>본 약관은 서비스에 가입된 회원을 포함하여 약관에 동의 후 서비스를 이용하고자 하는 모든 이용자에 대하여 그 효력이 발생합니다.</li>
                    <li>본 약관은 수시로 변경될 수 있으며, 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 초기화면에 그 개정약관의 적용일자 7일 전부터 적용일자
                      전일까지 공지한다.
                      다만, 회원에게 불리한 약관의 개정의 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지하며, 일정기간 서비스 내 로그인 시 동의 창 또는 전자적 수단을 통해 따로 명확히
                      통지하도록 합니다.
                    </li>
                    <li>회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
                      회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
                    </li>
                    <li>회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있으며, 다만, 기존 약관을 적용할 수 없는
                      특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제4조 (약관의 해석)</h5>
                  <p class="terms-description">이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정보통신망이용촉진등에관한법률(이하 "정통망법"),
                    전자상거래등에서의소비자보호에관한법률 등 관련법령 또는 상관례에 따릅니다.</p>
                </div>
                <div class="terms-wrap">
                  <h4>제2장 서비스 이용계약</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제5조 (서비스 이용계약 체결 및 승낙, 거부, 유보)</h5>
                  <ol class="terms-list-nodep">
                    <li>이용계약은 회원이 서비스이용에 대하여 동의를 하는 것으로 체결됩니다.</li>
                    <li>회사는 다음 각 호에 해당하는 회원의 서비스 이용에 대해서 결제승인을 하지 않거나 사후에 결제 승인을 취소할 수 있습니다.
                      <ul class="terms-list-dep2">
                        <li>1) 만 19세 미만의 자가 이용신청을 하는 경우</li>
                        <li>2) 신청자가 이 약관에 의하여 이전에 서비스 이용 자격을 상실한 적이 있는 경우, 다만, 회원자격 상실 후 1년이 경과한 자로서 회사의 승인을 얻은 경우에는 예외로
                          함.
                        </li>
                        <li>3) 타인의 휴대폰을 도용하여 서비스를 이용한 경우</li>
                        <li>4) 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
                        <li>5) 서비스 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우</li>
                        <li>6) 서비스를 이용함에 있어 부적절한 행위를 할 우려가 있다고 인정되거나 서비스를 정상적으로 이용할 수 없다고 합리적으로 판단되는 경우</li>
                      </ul>
                    </li>
                    <li>제1항에 따른 신청에 있어 회사는 회원에 대하여 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있다.
                      실명확인의 경우 법령에 따라 이용 가능한 신용정보업자 또는 신용정보집중기관을 통하여 주민등록번호와 성명의 일치 여부를 확인함으로써 실명확인절차를 진행하며,
                      회사는 실명확인절차를 취할 수 없는 이용신청에 대해서 이용신청자에게 증빙자료를 요청할 수 있습니다.
                    </li>
                    <li>회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 서비스를 중지할 수 있습니다.</li>
                    <li>제2항과 제4항에 따라 서비스이용을 제한하거나 유보한 경우, 회사는 원칙적으로 이를 서비스 이용자의 화면에 게시하거나 전자적 수단을 등을 통해 알리도록 합니다.</li>
                    <li>회원 중 개인은 개인회원으로 가입 신청하고 법인은 사업자회원으로 가입 신청하여야 한다. 개인사업자의 경우에도 그 사업과 관련하여 서비스를 이용하고자 하는 때에는
                      사업자회원으로 가입하여야 한다.
                      사업자회원으로 가입하고자 하는 자는 회사의 요청에 따라 사업자등록증, 대표자 주민등록증사본, 인감증명서, 결제 또는 판매대금 정산을 위한 대표자 또는 법인 명의의 계좌정보 등의
                      증빙자료와 추가 정보를 제공하여야 하며, 상기 자료는 “전자지급결제대행서비스업자"에 제공될 수 있습니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제6조 (아이디 및 비밀번호)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 회원의 원활한 이용을 위하여 회원에게 아이디와 비밀번호를 제공하고 있습니다.</li>
                    <li>아이디는 휴대전화번호당 1개의 아이디를 사용하는 것을 원칙으로 합니다. 다만, 요청이 있을 경우 수 개의 ID를 발급할 수 있으며, 이에 따라 발급된 수 개의 ID를 통합
                      관리하는 등 적절한 조치를 취할 수 있습니다.
                    </li>
                    <li>회원의 아이디 및 비밀번호에 관한 관리책임은 회원 본인에게 있으며 제3자에게 자신의 아이디 및 비밀번호를 알려주거나 이용하게 해서는 안됩니다.</li>
                    <li>회원이 자신의 아이디 및 비밀번호를 도용 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통보하여 그 안내에 따라야 합니다.</li>
                    <li>회원은 회사의 귀책사유 없이 ID 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나 손해에 대한 책임을 부담합니다.</li>
                    <li>회원은 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.</li>
                    <li>회원은 이용신청시 기재한 사항이 변경되었을 경우 온라인으로 수정하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.</li>
                    <li>전항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제7조 (등록비/관리비의 청구 및 반환)</h5>
                  <ol class="terms-list-nodep">
                    <li>다음 각 호에 해당하는 경우 회사는 회원에게 등록비나 관리비 또는 서비스 이용에 대한 수수료를 청구할 수 있습니다.
                      <ul class="terms-list-dep2">
                        <li>1) 회원이 서비스 이용을 위해 본 계약을 체결하는 경우</li>
                        <li>2) 회원이 중지된 서비스에 대한 재사용을 요청하는 경우</li>
                        <li>3) 회원이 회사에게 추가 계정 또는 추가 서비스를 요청할 경우</li>
                        <li>4) 기타 본 계약의 체결과 연장에 있어 회사에게 별도의 비용이 발생되는 경우</li>
                      </ul>
                    </li>
                    <li>회원이 납부한 비용은 회사의 귀책이 없는 한 서비스 이용 여부에 관계없이 환불하지 않습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제8조 (이용계약 성립)</h5>
                  <ol class="terms-list-nodep">
                    <li>이용계약은 서비스를 이용하고자 하는 자가 이 약관에 동의하고 서비스 이용에 대한 수수료 등의 계약 조건 확인 후 “회사”가 요청한 서류 또는 정보 등을 회사에 제출하는
                      방식으로 이용신청을 하고 회사가 이를 승낙함으로써 성립됩니다.
                      회사는 이용승낙의 의사표시를 서비스화면에 게시하거나 개별 통지합니다.
                    </li>
                    <li>타인의 명의를 도용하거나 허위의 정보를 제출하여 이용계약이 성립되거나, 이 약관에 반하여 이용계약이 성립된 경우 이용계약은 무효가 되며, 회원은 이로 인해 발생한 회사에
                      손해를 부담해야합니다.
                    </li>
                    <li>이용계약의 성립시기는 회사의 승낙이 신청자에게 도달한 시점으로 합니다.</li>
                    <li>회원과 회사는 서비스 이용에 대한 수수료 등과 관련한 별도의 합의를 할 수 있습니다.</li>
                    <li>회원은 특별우대수수료 서비스 등 특약서비스를 이용하고자 할 경우 회사와 별도 특약을 체결해야 합니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제9조 (계약해제, 해지 등)</h5>
                  <ol class="terms-list-nodep">
                    <li>회원은 언제든지 "고객센터"를 통하여 이용계약 해지 신청을 할 수 있으며, 회사는 관련법률 및 개인정보취급방침에서 정하는 바에 따라 이를 지체 없이 처리하여야 합니다.
                    </li>
                    <li>회원은 해지의사를 통지하기 전에 현재 진행중인 거래 절차를 완료, 철회 또는 취소해야만 합니다. 이 경우 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야
                      합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h4>제3장 결제나라서비스</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제10조 (결제나라서비스 내용)</h5>
                  <ol class="terms-list-nodep">
                    <li>“결제나라”는 회사에서 제공하는 결제기능을 통한 전자지급결제대행과 퀵비, 월세를 결제 및 납입, 정산을 대행하는 서비스를 제공합니다.
                      <ul class="terms-list-dep2">
                        <li>1) “퀵비 결제” 는 퀵비 사용료를 신용카드로 결제하는 서비스입니다.</li>
                        <li>2) “월세 납부” 는 월세, 관리비 등 임대료를 신용카드로 납부하는 서비스입니다.</li>
                        <li>3) 카톡결제, 수기결제, 단말기결제, 신용카드 QR결제, 페이허브 QR결제와 같은 다양한 결제수단을 사용할 수 있습니다.</li>
                      </ul>
                    </li>
                    <li>"결제나라"가 제공하는 서비스는 회원에게 "전자지급결제대행" 및 회원 이 체결한 거래의 실질이 확인된 계약에 따라 납부 대행 서비스를 제공하는 것에 그치며, 어떠한 경우에도
                      회원과 회원의 고객을 대리하지 않습니다.
                    </li>
                    <li>회사는 회원에게 거래 및 납부에 대한 중계서비스를 제공하는 것이므로 회원과 고객간에 이루어지는 거래와 관련된 일체의 위험과 책임은 해당 회원이 부담하며 회사는 어떠한 책임도
                      부담하지 않습니다.
                    </li>
                    <li>회원과 고객간의 거래와 관련된 책임은 거래 당사자인 회원과 고객에게 있습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제11조 (서비스 수수료)</h5>
                  <ol class="terms-list-nodep">
                    <li>서비스를 제공함에 있어 회사는 서비스 이용에 따르는 서비스 이용수수료를 부과할 수 있습니다.</li>
                    <li>회사의 서비스 이용 수수료는 서비스를 이용하여 승인된 거래에 대하여 회원에게 부과하는 수수료입니다.</li>
                    <li>서비스 이용 수수료는 정산 시 결제대금에서 공제합니다.</li>
                    <li>서비스 이용 수수료율은 서비스 내용 및 결제 수단에 따라 각각 다른 수수료율을 적용 받습니다.</li>
                    <li>회사는 서비스 이용 수수료율을 서비스 화면에 공지합니다.</li>
                    <li>서비스 이용 수수료율은 회사의 정책에 따라 변동될 수 있으며, 변동 시 서비스 공지사항에 이를 공지합니다.</li>
                    <li>서비스 이용수수료율 적용 후1원 단위 이하의 소수점 금액은 절사합니다.</li>
                    <li>회사는 서비스의 종류와 제공 내역, 이용방법이 변경되는 경우 서비스의 수수료를 변경할 수 있으며, 변경된 수수료는 변경된 수수료가 적용되는 날부터 결제되는 거래 건에 대하여
                      적용합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h4>제4장 계약당사자의 의무</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제12조 (회사의 의무)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 서비스 이용 정책 또는 이용약관을 준수합니다.</li>
                    <li>회사는 관련 법률과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.</li>
                    <li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.</li>
                    <li>회사는 서비스를 이용하는 회원과 고객간의 거래와 관련하여 분쟁이 발생한 경우 그 분쟁의 원활한 해결을 위하여 노력합니다.</li>
                    <li>회사는 이용계약 종료 이후에도 계약기간 중 서비스를 이용하여 발생한 상품 구매에 대하여 민원발생의 요소가 완전히 해소되었다는 상호 합의가 있을 때까지 대금정산의 반환을
                      보류할 수 있습니다.
                    </li>
                    <li>회사는 서비스 제공자로서 관련 법령에서 정하는 기간동안 필요정보를 보관합니다. 또한 보관중인 자료에 대하여 권리가 있는 자의 요청이 있는 경우 관련 자료를 제공할 수
                      있습니다.
                    </li>
                    <li>회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을
                      활용하거나 전자적 표시방법 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제13조 (회원의 의무)</h5>
                  <ol class="terms-list-nodep">
                    <li>회원은 다음 행위를 하여서는 안됩니다.
                      <ul class="terms-list-dep2">
                        <li>1) 신청 또는 변경 시 허위내용의 등록</li>
                        <li>2) 타인의 정보도용</li>
                        <li>3) 회사가 게시한 정보의 변경</li>
                        <li>4) 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
                        <li>5) 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                        <li>6) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                        <li>7) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위</li>
                        <li>8) 기타 불법적이거나 부당한 행위</li>
                      </ul>
                    </li>
                    <li>회원은 관련법률, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는
                      안됩니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h4>제5장 서비스 제공 등</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제14조 (서비스 이용시간)</h5>
                  <ol class="terms-list-nodep">
                    <li>서비스는 업무상 또는 기술상에 특별한 지장이 없는 한 연중무휴, 1일 24시간 제공함을 원칙으로 하나 개별 서비스에 따라 서비스 이용 가능 시간에 제한을 둘 수
                      있습니다.
                    </li>
                    <li>회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제15조 (서비스의 중단 및 변경)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
                    <li>회사는 운영상, 기술상 상당한 이유가 있는 경우 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.</li>
                    <li>회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공지한 바에 따릅니다. 다만, 회사가 사전에 통지할 수 없는 부득이한
                      사유가 있는 경우 사후에 통지할 수 있습니다.
                    </li>
                    <li>본 조항 1,2에 따라 서비스가 변경, 중단 될 경우 는 제15조[회원에 대한 통지]에 정한 방법으로 회원에게 통지합니다.</li>
                  </ol>
                </div>

                <div class="terms-wrap">
                  <h5>제16조 (회원에 대한 통지)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 SMS(단문메세지), 이메일, 앱 서비스내 알림메세지 등으로 할 수 있습니다.</li>
                    <li>회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제17조 (이용제한 등)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 다음과 같은 사유가 있는 경우, 서비스 이용을 제한할 수 있습니다.
                      <ul class="terms-list-dep2">
                        <li>1) 회원이 회사가 제공하는 서비스를 그 목적에 벗어나거나 위배하여 이용한 것으로 확인된 경우</li>
                        <li>2) 회원이 다른 회원 또는 회원의 고객 또는 기타 타인에 대한 권리나 명예, 신용, 정당한 이익을 방해하거나 침해한 경우</li>
                        <li>3) 제출 또는 기입한 서류 또는 정보에 허위 기재, 누락, 오기 또는 미비사항이 있는 경우</li>
                        <li>4) 약관에 의하여 서비스 이용자격을 상실한 적 있는 경우</li>
                        <li>5) 회원이 회사의 권리나 명예, 정당한 이익을 방해 또는 침해하거나 회사의 원활한 서비스 제공을 방해하거나 방해하고자 시도한 경우</li>
                        <li>6) 회원이 사회의 안녕질서, 공공질서, 미풍양속에 위배되는 거래행위를 하거나 시도한 경우</li>
                        <li>7) 기타 위 각호의 사유가 있는 것으로 의심되거나 이 약관에 위배되거나 위법 또는 부당한 이용신청인 경우 또는 회사가 합리적인 판단에 의하여 필요하다고 인정하는
                          경우
                        </li>
                      </ul>
                    </li>
                    <li>동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다. 회사가 회원 자격을 상실시키는 경우에는
                      회원등록을 말소합니다.
                    </li>
                    <li>회사가 회원 자격을 일시정지 또는 상실시키는 경우, 회원에게 이를 통지하고, 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</li>
                    <li>회사는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해,
                      "정통망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다.
                      본 항에 따른 영구이용정지 시 서비스 이용을 통해 획득한 혜택이 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
                    </li>
                    <li>회사는 회원이 계속해서 12개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 휴면계정으로 전환됩니다.</li>
                    <li>회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 따릅니다.</li>
                    <li>본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제15조[회원에 대한 통지]에 따라 통지합니다.</li>
                    <li>회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을
                      재개합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h4>제6장 정산</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제18조 (수수료 지급)</h5>
                  <ol class="terms-list-nodep">
                    <li>회원의 서비스 이용대가로 회사에게 수수료를 이 약관에서 정한 바에 따라 대금정산시 회사가 고객사에 지급해야하는 결제금액에서 차감하는 방식으로 지급합니다.</li>
                    <li>회사는 수수료 및 정산 주기를 변경할 수 있으며, 변경시 회원에 변경사항을 통지합니다. 다만 수수료가 인하되는 경우에는 별도 통지하지 아니할 수 있습니다.</li>
                    <li>회원이 회사에 대한 수수료 지급의무는 이용계약이 해지 또는 종료되더라도 지급할 수수료가 존재하는 한 계속되는 것으로 봅니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제19조 (특별우대수수료)</h5>
                  <p class="terms-description">공통적인 결제수수료 기준에도 불구하고 회사의 정책에 따라 회원에게 결제 수수료에 대하여 특별우대수수료를 적용할 수 있습니다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제20조 (대금정산)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 서비스를 통해 발생한 거래승인 건 중 회사가 결제기관 등으로부터 대금을 지급받은 거래승인 건을 대상으로 대금정산을 하며, 그 구체적인 기준은 계약서에 명시된 수수료
                      부분에 명시되거나 별도 합의한 바와 같습니다.
                    </li>
                    <li>대금정산시 회사는 정산대금에서 회원으로부터 지급받을 수수료를 상계한 후 그 잔액을 회원에게 지급합니다.</li>
                    <li>회사와 회원은 대금정산과 관련하여 과오납이 있음을 발견한 때에는 이를 지체없이 상대방에게 통지하고 재정산해야 합니다.</li>
                    <li>회원이 정산대금을 지급받은 후 취소 금액 발생시 회사가 차기 정산금액에서 차감되며, 만약 차감할 금액이 없는 경우 회원은 회사로부터 취소금액에 대한 입금요청을 받은 7일이내
                      회사에 해당 금액을 지급하여야 합니다.
                    </li>
                    <li>회원이 회사의 서비스를 이용하여 발생한 정산대금을 제3자에게 양도하고자 하는 경우에는 회사의 동의를 받아야 하며, 동의 없는 양도 행위는 회사에게 대항할 수 없습니다.
                    </li>
                    <li>회사가 결제기관의 사유로 정산대금의 입금이 지연 또는 불가능한 경우에 회원은 회사에게 책임을 물을 수 없습니다.</li>
                  </ol>
                  <p class="terms-description">공통적인 결제수수료 기준에도 불구하고 회사의 정책에 따라 회원에게 결제 수수료에 대하여 특별우대수수료를 적용할 수 있습니다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제21조 (지급보류)</h5>
                  <ol class="terms-list-nodep">
                    <li>서비스를 이용하여 현금할인 및 재판매 등의 행위를 한 경우</li>
                    <li>정산예정금액의 비정상적 증대 또는 감소, 정산된 회원의 거래가 민원, 이의제기 등으로 취소될 개연성이 있는 경우</li>
                    <li>이용계약 무효 사유가 존재하거나 이용신청에 대한 회사의 승낙이 이루어지기 전 정산 대금이 발생한 경우</li>
                    <li>비정상거래로 확인되거나 의심되는 경우</li>
                    <li>약관, 관련 법령, 회사의 정책을 위반하거나 위반한 것으로 의심되는 경우</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h4>제7장 개인정보 보호와 의무</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제22조 (개인정보보호 의무)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 "정통망법" 등 관련법률이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법률 및 회사의 개인정보취급방침이
                      적용됩니다.
                    </li>
                    <li>회사가 회원의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 해당 회원의 동의를 받습니다.</li>
                    <li>제공된 개인정보는 당해 회원의 동의없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다. 다만, 다음의 경우에는 예외로 합니다.
                      <ul class="terms-list-dep2">
                        <li>1) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
                        <li>2) 거래에 따른 대금 정산을 위하여 필요한 경우</li>
                        <li>3) 도용 방지를 위하여 본인확인에 필요한 경우</li>
                        <li>4) 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</li>
                        <li>5) 당사 또는 제휴사와 관련하여 합병, 인수, 포괄적 영업양도 등이 있는 경우 합병 후 당사, 인수당사 및 영업양수인에 대하여 제공하는 경우</li>
                      </ul>
                    </li>
                    <li>회사가 제2항과 제3항에 의해 회원의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적,
                      제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 "정통망법"에서 규정한 사항을 미리 명시하거나 고지해야 하며 회원은 언제든지 이 동의를
                      철회할 수 있습니다.
                    </li>
                    <li>회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 회원이
                      오류의 정정을 요구한 경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
                    </li>
                    <li>회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 다만, 아래의 경우에는 회원
                      정보를
                      보관합니다. 이 경우 회사는 보관하고 있는 회원 정보를 그 보관의 목적으로만 이용합니다.
                      <ul class="terms-list-dep2">
                        <li>1) 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원 정보를
                          보관합니다.
                        </li>
                        <li>2) 회사가 이용계약을 해지하거나 회사로부터 서비스 이용정지조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기 위한 목적으로
                          이용계약종료 후 3년간 아이디, 성명 또는 상호, 연락처, 주소를 비롯하여 이용계약 해지와 서비스 이용 정지와 관련된 정보 등의 필요 정보를 보관합니다.
                        </li>
                        <li>3) 기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원 정보를 보관합니다.</li>
                      </ul>
                    </li>
                    <li>회사는 새로운 업체가 제휴사 또는 제휴영업점의 지위를 취득할 경우 제15조에서 정한 것과 같은 방법을 통하여 공지합니다. 이 때 회원이 별도의 이의제기를 하지 않을 경우
                      회사 서비스 제공이라는 필수적인 목적을 위해 해당 개인 정보 제공 및 활용에 동의한 것으로 간주 합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h4>제8장 기타</h4>
                </div>
                <div class="terms-wrap">
                  <h5>제23조 (책임제한)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
                    <li>회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</li>
                    <li>회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제24조 (준거법 및 재판관할)</h5>
                  <ol class="terms-list-nodep">
                    <li>회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                    <li>서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>부칙</h5>
                  <p class="terms-description">이 약관은 2021년 11월 29일부터 적용됩니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
export default {
  name: "Term1",
  path: '/term/1',
}
</script>

<style scoped>

</style>

<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 guide section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'Guide1'})"><span>비용안내</span></a></li>
            <li><a @click="$router.push({name:'Guide2'})"><span>가입·결제받기</span></a></li>
            <li><a @click="$router.push({name:'Guide3'})"><span>구비서류·심사</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'Guide4'})"><span>보증보험</span></a></li>
            <li><a @click="$router.push({name:'Guide5'})"><span>정산안내</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 guid4 ======= -->
    <section class="guide section2">
      <div class="container guid">
        <!-- 보증보험 -->
        <div class="heading-tit">
          <h2>보증보험</h2>
          <p>보증보험 가입을 요청 드리는 기준과 가입절차에 대한 안내입니다.</p>
        </div>
        <div class="panel-block">
          <div class="panel-block-area">
            <p class="check">서류검토/업종심사 과정에서 결제규모 및 업종에 따라 보증보험을 요청 드릴 수 있습니다.</p>
          </div>
        </div>
        <!-- 신청대상 -->
        <div class="panel">
          <h3>신청대상</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-desc col-12">
                <ul>
                  <li>1. 건당 결제금액이 10만원 이상 또는 월 매출 300만원 이상인 가맹점</li>
                  <li>2. 판매자가 동일 구매자에게 분할결제를 받는 경우 합산금액으로 판단하여 보증보험을 요청합니다.</li>
                  <li>3. 실물결제가 아닌 콘텐츠, 방문판매, 건강식품, 해외결제, 그리고 페이앱이 지정한 업종은 월 매출의 100%에 해당하는 보증보험을 진행하셔야 합니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 보험비용 -->
        <div class="panel">
          <h3>보험비용</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-desc col-12">
                <ul class="ex-li">
                  <li>보증보험 가입 시 납부하시는 보험료는 ‘1년 총액’ 기준이며, 보증금액에 따라 보험금액의 1.5%~0.953%입니다.</li>
                  <li>판매점 매출규모, 업종 특성에 따라 가입하실 보험금액을 고객센터에서 별도로 안내 드립니다. 보증보험의 계약기간은 1년으로, 만료 시 재가입이 필요합니다.</li>
                  <li>보험비용은 페이허브가 아닌, 서울보증보험에 납부하시는 금액입니다.</li>
                  <li class="point-color">1건이라도 거래내역이 있을 경우 보증보험은 해지/환불되지 않습니다.</li>
                  <li class="point-color">페이허브가 수취하는 비용이 아니므로 환불이 되지 않는 점에 대해 양해 부탁 드립니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 수수료 -->
        <div class="panel">
          <h3>수수료</h3>
          <div class="line">
            <div class="panel-list row justify-content-between">
              <div class="panel-list-tit col-4 col-md-3">
                <b class="d-block">보증보험</b>
                <small class="d-block pt-3">최소 100만원</small>
              </div>
              <div class="col-8 col-md-9">
                <div class="li-group row">
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>100만원</span>
                  </div>
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>300만원</span>
                  </div>
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>500만원</span>
                  </div>
                  <div class="panel-list-desc col-md-3">
                    <span>1,000만원</span>
                  </div>
                </div>
              </div>
            </div>
            <p class="panel-line"></p>
            <div class="panel-list row justify-content-between">
              <div class="panel-list-tit col-4 col-md-3">
                <b class="d-block">보험료 율</b>
                <small class="d-block pt-3">연 0.953%</small>
              </div>
              <div class="col-8 col-md-9">
                <div class="li-group row">
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>1.5%</span>
                  </div>
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>0.953%</span>
                  </div>
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>0.953%</span>
                  </div>
                  <div class="panel-list-desc col-md-3">
                    <span>0.953%</span>
                  </div>
                </div>
              </div>
            </div>
            <p class="panel-line"></p>
            <div class="panel-list row justify-content-between">
              <div class="panel-list-tit col-4 col-md-3">
                <b class="d-block">연간 보험료</b>
                <small class="d-block pt-3">1년 부담총액</small>
              </div>
              <div class="col-8 col-md-9">
                <div class="li-group row">
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>15,000원</span>
                  </div>
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>28,590원</span>
                  </div>
                  <div class="panel-list-desc col-md-3 pb-2 pb-sm-0">
                    <span>47,650원</span>
                  </div>
                  <div class="panel-list-desc col-md-3">
                    <span>95,300원</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- 신청단계 -->
        <div class="panel">
          <h3>신청단계</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="step">
                <div class="depth">
                  <img src="/image/guide_step1.png" alt="보증보험 인증">
                  <span>보증보험 인증</span>
                </div>
                <div class="depth">
                  <img src="/image/guide_step2.png" alt="페이허브 접수">
                  <span>페이허브 접수</span>
                </div>
                <div class="depth pt-4 pt-md-0">
                  <img src="/image/guide_step3.png" alt="전자서명">
                  <span>전자서명</span>
                </div>
                <div class="depth pt-4 pt-md-0">
                  <img src="/image/guide_step4.png" alt="보험계약 체결">
                  <span>보험계약 체결</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 신청방법 -->
        <div class="panel">
          <h3 >신청방법</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-tit col-12 pb-4">
                01. APP설치
              </div>
              <div class="panel-list-desc col-12">
                <p class="pb-4">
                  ‘SGI서울보증’ 앱을 설치해주세요. https://sgic.co.kr 에서도 신청가능 합니다.
                </p>
                <div class="panel-list-btn">
                  <button class="table-btn">
                    <span class="icon-google hidden">구글 스토어</span>
                  </button>
                  <button class="table-btn ">
                    <span class="icon-app hidden">앱 스토어</span>
                  </button>
                </div>
              </div>
            </div>
            <p class="panel-line"></p>
            <div class="panel-list row">
              <div class="panel-list-tit col-12 pb-4">
                02. 동의 및 인증
              </div>
              <div class="panel-list-desc col-12">
                <ul class="panel-x-scroll d-flex pt-5 pm-5 jus justify-content-sm-between">
                  <li class="col-sm-4 col-md-3">
                    <em class="guide-step">SGI서울보증 앱 실행</em>
                    <div class="img-box">
                      <img src="/image/sgi-screenshot1.png" alt="">
                    </div>
                  </li>
                  <li class="col-sm-4 col-md-3">
                    <em class="guide-step">계약체결 필수 동의 버튼 클릭</em>
                    <div class="img-box">
                      <img src="/image/sgi-screenshot2.png" alt="">
                    </div>
                  </li>
                  <li class="col-sm-4 col-md-3">
                    <em class="guide-step">주민번호 입력 후 인증완료</em>
                    <div class="img-box">
                      <img src="/image/sgi-screenshot3.png" alt="">
                    </div>
                  </li>
                </ul>

              </div>
            </div>
            <p class="panel-line"></p>
            <div class="panel-list row">
              <div class="panel-list-tit col-12 pb-4">
                03. API 연동
              </div>
              <div class="panel-list-desc col-12">
                <p class="pb-4">
                  연동 매뉴얼을 참고해 쇼핑몰/사이트/서비스에 쉽게 결제연동 가능
                </p>
              </div>
            </div>
            <p class="panel-line"></p>
            <div class="panel-list row">
              <div class="panel-list-tit col-12 pb-4">
                04. 전자서명 진행
              </div>
              <div class="panel-list-desc col-12">
                <p class="pb-4">
                  페이허브앱을 통해 접수가 완료된 경우 서울보증보험에서 전화나 문자로 보증보험에 대한 전자서명 요청이 진행되며, 전자서명 시 회원가입을 진행하셔야 합니다.
                </p>
              </div>
            </div>
            <p class="panel-line"></p>
            <div class="panel-list row">
              <div class="panel-list-tit col-12 pb-4">
                05. 신청완료
              </div>
              <div class="panel-list-desc col-12">
                <p class="pb-4">
                  전자서명 완료시 보험료 납부 안내가 전달되며, 입금시 계약이 완료됩니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 공지사항 -->
        <div class="panel">
          <h3>공지사항</h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-desc col-12">
                <p class="pb-4">
                  페이허브 본사에서 지정한 서울보증보험(잠실지점)이 아닌 다른지점을 통한 발급시 아래의 메일 또는 팩스로 보증보험 영수증을 제출해 주셔야 합니다.
                </p>
                <p class="d-block pb-4">
                  <b class="comm">이메일 : <a href="mailto:cs@payhub.co.kr">cs@payhub.co.kr</a></b>
                </p>
                <p class="d-block">
                  <b class="comm">팩스 : 02-6008-9760</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End guid4 -->
  </main>
</template>

<script>
export default {
name: "Guide4",
  path: '/guide/4',
}
</script>

<style scoped>

</style>

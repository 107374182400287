<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 guide section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li class="fucus"><a @click="$router.push({name:'Guide1'})"><span>비용안내</span></a></li>
            <li><a @click="$router.push({name:'Guide2'})"><span>가입·결제받기</span></a></li>
            <li><a @click="$router.push({name:'Guide3'})"><span>구비서류·심사</span></a></li>
            <li><a @click="$router.push({name:'Guide4'})"><span>보증보험</span></a></li>
            <li><a @click="$router.push({name:'Guide5'})"><span>정산안내</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->

    <!-- ======= section2 guid1 ======= -->
    <section class="guide section2">
      <div class="container guid">
        <!-- 비용안내 -->
        <div class="heading-tit">
          <h2>비용안내</h2>
          <p>수수료외에는 어떠한 추가 비용도 발생하지 않습니다.</p>
        </div>
        <div class="panel-block">
          <div class="panel-block-area">
            <p class="check">결제나라 판매점에 드리는 특전과 수수료 조건을 확인하세요. 결제수수료 외 어떠한 추가비용도 발생하지 않습니다.</p>
          </div>
        </div>
        <!-- 등록비 -->
        <div class="panel">
          <h3>등록비<small>VAT 별도</small></h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-tit  pb-3 pb-md-0 col-sm-12 col-md-2">
                초기등록비
              </div>
              <div class="panel-list-desc col-sm-12 col-md-10">
                <div class="price">
                  <span class="r-arrow">120,000원</span><strong class="price-red pl-5">0원 (무료이벤트)</strong>
                </div>
              </div>
            </div>
            <p class="panel-line"></p>
            <div class="panel-list row">
              <div class="panel-list-tit pb-3 pb-md-0 col-sm-12 col-md-2">
                유지비
              </div>
              <div class="panel-list-desc col-sm-12 col-md-10">
                <div class="price">
                  <strong class="price-red">0원</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="ex-list">
            <ul>
              <li>무료 이벤트는 별도 예고없이 종료될 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <!-- 결제수수료 -->
        <div class="panel">
          <h3>결제수수료<small>VAT 별도</small></h3>
          <div class="line">
            <div class="panel-list row">
              <div class="panel-list-tit pb-3 pb-md-0 col-sm-12 col-md-3 col-lg-2">
                <p>사업자 (법인/개인)</p>
                <p>비사업자 개인</p>
              </div>
              <div class="panel-list-desc col-sm-12 col-md-9 col-lg-10">
                <div class="panel-list-wrap">
                  <div class="panel-list-group">
                    <div class="p-item">
                      <p class="price">페이허브 QR결제 :</p>
                      <strong class="per">0%</strong>
                    </div>
                    <div class="p-item">
                      <p class="price">신용카드 QR결제 :</p>
                      <strong class="per">0%</strong>
                    </div>
                    <div class="p-item">
                      <p class="price">수기결제 :</p>
                      <strong class="per">0%</strong>
                    </div>
                    <div class="p-item">
                      <p class="price">카톡결제 :</p>
                      <strong class="per">0%</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 우대수수료 -->
        <!-- <div class="panel">
            <h3>우대수수료<small>VAT 별도</small></h3>
            <div class="line">
                <div class="panel-list row">
                    <div class="panel-list-desc col-md-12 col-lg-6">
                        <div class="panel-list-wrap">
                            <div class="panel-list-group">
                                <p class="price">영세 - 연 매출 3억원 이하 : <span class="price-red b-arrow pr-4">1.3% 인하</span></p>
                                <strong class="per">2.1%</strong>
                            </div>
                            <div class="panel-list-group">
                                <p class="price">중소2 (준중소) - 연 매출 3억원 이하 : <span class="price-red b-arrow pr-4">0.7% 인하</span></p>
                                <strong class="per">2.7%</strong>
                            </div>
                            <div class="panel-list-group pb-4 pb-lg-0">
                                <p class="price">일반 - 연 매출 30억 이상 : <span class="price-red pr-1">미적용</span></p>
                                <strong class="per">3.4%</strong>
                            </div>
                        </div>
                    </div>
                    <div class="panel-list-desc col-md-12 col-lg-6">
                        <div class="panel-list-wrap">
                            <div class="panel-list-group">
                                <div class="panel-list-group">
                                    <p class="price">중소1 (중소) - 연 매출 3~5억원 : <span class="price-red b-arrow pr-4">0.8% 인하</span></p>
                                    <strong class="per">2.6%</strong>
                                </div>
                                <div class="panel-list-group">
                                    <p class="price">중소2 (중견) - 연 매출 10~30억원 : <span class="price-red b-arrow pr-4">2.9% 인하</span></p>
                                    <strong class="per">2.9%</strong>
                                </div>
                                <div class="panel-list-group">
                                    <p class="price">신규 (등록1년이내) - 연 매출 미산출 : <span class="price-red pr-1">미적용</span></p>
                                    <strong class="per">3.4%</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ex-list">
                <ul>
                    <li>자영업자, 소상공인 부담경감을 위해 수수료를 차액정산해 드립니다.</li>
                    <li>상기 영중소 우대수수료는 사업자(법인/개인)의 경우에만 해당됩니다.</li>
                    <li>가맹점 구분 기준은 국세청 및 여신금융협회 자료를 기준으로 조정되며, <b>연매출 변동에 따라 상향 또는 하향될 수 있습니다.</b></li>
                    <li>간편결제의 경우 신용카드결제만 우대수수료 적용 대상이며, 카카오머니, 네이버계좌/포인트 등 현금성 결제분은 해당되지 않습니다.</li>
                    <li class="text-red"> PG사 가입 당시에 국세청에 신고된 카드 매출액이 파악되지 않는 신규가맹점에는 우대수수료율이 적용되지 않으며, 일반가맹점 수수료율이 적용됩니다.</li>
                </ul>
            </div>
        </div> -->
      </div>
    </section><!-- End guid1 -->
  </main>
</template>

<script>
export default {
name: "Guide1",
  path: '/guide/1',
}
</script>

<style scoped>

</style>

<template>
  <main class="sub-main">
    <!-- ======= section1 tab ======= -->
    <section class="section-80 support  section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'Faq'})"><span>자주묻는 질문</span></a></li>
            <li><a @click="$router.push({name:'Notice'})"><span>공지사항</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'InquiryList'})"><span>문의사항</span></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End section1 -->
    <!-- ======= section2 Lnquiry-search ======= -->
    <section class="support section2">
      <div class="container">
        <div class="heading-tit d-notice">
          <h2>문의사항</h2>
          <div class="search-input">
            <input type="text" placeholder="검색어를 입력하세요.">
            <button type="button" class="btn-search">
              <img src="/image/icon_search.svg" alt="검색">
            </button>
          </div>
        </div>
        <div class="support-content">
          <ul class="notice-list">
            <li>
              <a @click="$router.push({name:'InquiryView', params:{idx:'5'}})">
                <div class="list-tit">
                  <div class="left-box">
                    <span class="bk-bedge">공지</span>
                  </div>
                  <div class="right-box lnquiry-box">
                    <p class="tit f-tran">
                      <em class="admin-tit">[연동문의]</em> 결제나라 업데이트 안내 결제나라 업데이트 안내결제나라 업데이트 안내 <i class="sprites folder"></i>
                    </p>
                    <div class="txt f-tran">
                      <p class="txt-con">
                        <span class="type">신규문의</span>
                        <span class="user bar">결제나라</span>
                        <span class="date bar">2021.10.15 10:00:00</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="list-tit">
                  <div class="left-box">
                    <span class="num">55</span>
                  </div>
                  <div class="right-box lnquiry-box">
                    <p class="tit f-tran">
                      <em class="admin-tit">[연동문의]</em> 결제나라 업데이트 안내 <i class="sprites security"></i>
                    </p>
                    <div class="txt f-tran">
                      <p class="txt-con">
                        <span class="type">신규문의</span>
                        <span class="user bar">결제나라</span>
                        <span class="date bar">2021.10.15 10:00:00</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <div class="list-btn d-flex justify-content-center justify-content-md-end">
            <button class="outline-middle-btn" onclick="location.href='inquiry_seller_write.html'">문의하기</button>
          </div>
          <ul class="paging">
            <li><a href=""><img src="/image/icon_left_double.svg" alt="이전"></a></li>
            <li><a href=""><img src="/image/icon_left.svg" alt="이전"></a></li>
            <li><a href="#" class="on">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li><a href="#">5</a></li>
            <li class="rotate"><a href="#"><img src="/image/icon_left.svg" alt="다음"></a></li>
            <li class="rotate"><a href="#"><img src="/image/icon_left_double.svg" alt="다음"></a></li>
          </ul>
        </div>
      </div>
    </section><!-- End Lnquiry-search -->
  </main>
</template>

<script>
export default {
  name: "InquiryList",
  path: '/support/inquiries',
}
</script>

<style scoped>

</style>

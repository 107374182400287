<template>
  <main class="main100">
    <div class="para-80">
      <div class="container">
        <div class="container-content">
          <div class="terms">
            <!-- 탭 -->
            <div class="function-tab function-tab3 mb-50">
              <ul>
                <li>
                  <a @click="$router.push({name:'Term1'})">
                    <span>서비스 이용약관</span></a>
                </li>
                <li class="fucus">
                  <a @click="$router.push({name:'Term2'})">
                    <span>개인정보처리방침</span></a>
                </li>
                <li>
                  <a @click="$router.push({name:'Term3'})">
                    <span>전자금융거래 이용약관</span></a>
                </li>
              </ul>
            </div>
            <!-- 서비스 이용약관 -->
            <div class="function-cont">
              <h2 class="term-title mt-30 mb-50">개인정보처리방침</h2>
              <!-- 개인정보 처리방침 -->
              <div class="terms-content">
                <div class="terms-top-box">
                  <p>개인정보처리방침에서는 다음과 같은 사항을 규정합니다.<br><br></p>
                  <p>주식회사 페이허브(이하 “회사”라 함)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은
                    처리방침을 두고 있습니다.</p>
                  <p>회사는 개인정보처리방침을 개정하는 경우 웹사이트/결제나라 앱 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제1조 개인정보의 처리 목적</h5>
                  <p class="terms-description">회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될
                    시에는 사전동의를 구할 예정입니다.</p>
                  <ol class="terms-list-dep1">
                    <li class="withmarker">
                      <span class="number-span">결제나라 회원가입 및 관리</span>
                      <ul class="terms-list-dep2 list-dotted last-list">
                        <li>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종
                          고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
                        </li>
                      </ul>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">재화 또는 서비스 제공</span>
                      <ul class="terms-list-dep2 list-dotted last-list">
                        <li>서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 결제, 송금, 출금 등을 목적으로 개인정보를 처리합니다.</li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제2조 개인정보의 항목 및 수집방법</h5>
                  <ol class="terms-list-nodep">
                    <li>처리하는 개인정보 항목
                      <ul class="terms-list-dep2">
                        <li>1) 이용자의 성명(사업자의 경우, 대표자명 및 담당자명), 생년월일, 휴대폰번호(사업자의 경우 대표자 휴대폰번호 및 담당자 휴대폰번호),
                          고유식별정보, 전화번호, 이메일 주소(사업자의 경우 대표자 및 담당자 이메일 주소), 비사업자의 경우 대표자 주민번호, 상호명, 사업자번호(법인번호), 업종 및 업태,
                          사업자 품목, 가격, 사업자가 수집하는 식별되지 않는 고객정보,
                          사업장주소, 대표번호, 팩스번호, 거래대금 지급정보(결제은행, 계좌번호, 계좌명) 및 상품 또는 용역 거래정보 등
                        </li>
                        <li>2) 상기 명시된 개인정보 수집항목 이외의 서비스 이용과정이나 서비스 처리과정에서 추가 정보(접속 IP/MAC Address, 쿠키, e-mail, 서비스 접속
                          일시, 서비스 이용기록, 불량 혹은 비정상 이용기록, 결제기록)들이 자동 혹은 수동으로 생성되어 수집 될 수 있습니다.
                        </li>
                      </ul>
                    </li>
                    <li>개인정보 수집방법
                      <ul class="terms-list-dep2">
                        <li>1) 모바일앱, 홈페이지, 서면양식, 전화, 상담 게시판, 이메일, 팩스</li>
                        <li>2) 업무제휴 계약을 체결한 제휴사로부터 고객이 제시하는 개인정보 수집</li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제3조 개인정보의 처리 및 보유 기간</h5>
                  <p class="terms-description">회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유·이용기간 내에서
                    개인정보를 처리·보유합니다.</p>
                  <ol class="terms-list-nodep">
                    <li>회원가입 및 관리, 민원사무 처리, 재화 또는 서비스 제공, 마케팅 및 광고의 활용과 관련한 개인정보는 수집·이용에 관한 동의일로부터 지체 없이 파기까지 위 이용목적을
                      위하여 보유·이용 됩니다.
                    </li>
                    <li>상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
                      이 경우 회원정보는 정보제공일로부터 서비스 해지일 또는 이용계약 종료일까지 보유 및 이용 (단, 관계법령에 따라 보존할 필요성이 있는 경우는 규정에 따라 보존)합니다.
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제4조 개인정보의 제3자 제공에 관한 사항</h5>
                  <p class="terms-description">회사는 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우 외에는 개인정보를 제3자에게 제공하지
                    않습니다. 다만, 아래의 경우는 예외로 합니다.</p>
                  <ol class="terms-list-nodep">
                    <li>이용자들이 사전에 동의한 경우(이용자가 사전에 동의한 경우란, 서비스 이용 등을 위하여 이용자가 자발적으로 자신의 개인정보를 제3자에게 제공하는 것에 동의하는 것을
                      의미합니다.)
                    </li>
                    <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                    <li>위의 경우에도, 회사는 이용자에게
                      <ul class="terms-list-dep2">
                        <li>1) 개인정보를 제공받는 자,</li>
                        <li>2) 그의 이용목적,</li>
                        <li>3) 제공되는 개인정보의 항목,</li>
                        <li>4) 개인정보의 보유 및 이용기간을 사전에 고지하고 이에 대해 명시적인 방법으로 개별적 동의를 얻습니다. 이와 같은 모든 과정에 있어서 회사는 이용자의 의사에 반하여
                          추가적인 정보를 수집하거나, 동의의 범위를 벗어난 정보를 제3자와 공유하지 않습니다.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제5조 개인정보처리 위탁</h5>
                  <p class="terms-description">회사는 모든 개인정보를 직접 관리하고 있습니다.</p>
                  <ol class="terms-list-nodep">
                    <li>회사는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상
                      등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하겠습니다.
                    </li>
                    <li>위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제6조 정보주체의 권리·의무 및 그 행사방법</h5>
                  <p class="terms-description">이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</p>
                  <ol class="terms-list-nodep">
                    <li>정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
                      <ul class="terms-list-dep2">
                        <li>1) 개인정보 열람요구</li>
                        <li>2) 오류 등이 있을 경우 정정 요구</li>
                        <li>3) 삭제요구</li>
                        <li>4) 처리정지 요구</li>
                      </ul>
                    </li>
                    <li>제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조제1항 서식에 따라 서면, 전자우편, 팩스 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이
                      조치하겠습니다.
                    </li>
                    <li>정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</li>
                    <li>제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.</li>
                    <li>개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>
                    <li>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
                    <li>회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제7조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h5>
                  <p class="terms-description">회사는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
                    <br>쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>
                  <ol>
                    <li class="withmarker">
                      <span class="number-span">쿠키의 사용 목적</span>
                      <p class="terms-description2">이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
                        최적화된 정보 제공을 위해 사용됩니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">쿠키의 설치·운영 및 거부</span>
                      <p class="terms-description2">웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">단, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</span></li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제8조 개인정보 파기</h5>
                  <p class="terms-description">회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과
                    같습니다.</p>
                  <ol class="terms-list-dep1">
                    <li class="withmarker">
                      <span class="number-span">파기절차</span>
                      <ul class="terms-list-dep2 list-dotted last-list">
                        <li>이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시
                          파기됩니다.
                        </li>
                        <li>이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</li>
                      </ul>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">파기기한</span>
                      <ul class="terms-list-dep2 list-dotted last-list">
                        <li>이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그
                          개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
                        </li>
                      </ul>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">파기방법</span>
                      <ul class="terms-list-dep2 list-dotted last-list">
                        <li>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</li>
                        <li>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
                      </ul>
                    </li>
                  </ol>

                </div>
                <div class="terms-wrap">
                  <h5>제9조 개인정보의 안전성 확보 조치</h5>
                  <p class="terms-description">회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</p>
                  <ol>
                    <li class="withmarker">
                      <span class="number-span">정기적인 자체 감사 실시</span>
                      <p class="terms-description2">개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">개인정보 취급 직원의 최소화 및 교육</span>
                      <p class="terms-description2">개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">내부관리계획의 수립 및 시행</span>
                      <p class="terms-description2">개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">해킹 등에 대비한 기술적 대책</span>
                      <p class="terms-description2">회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인
                        갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">개인정보의 암호화</span>
                      <p class="terms-description2">이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
                        데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">접속기록의 보관 및 위변조 방지</span>
                      <p class="terms-description2">개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록
                        보안기능 사용하고 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">개인정보에 대한 접근 제한</span>
                      <p class="terms-description2">개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
                        조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</p>
                    </li>
                    <li class="withmarker">
                      <span class="number-span">비인가자에 대한 출입 통제</span>
                      <p class="terms-description2">개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</p>
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제10조 개인정보 보호책임자 작성</h5>
                  <p class="terms-description">회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
                    같이 개인정보 보호책임자를 지정하고 있습니다.</p>
                  <ul class="manager-info list-dotted">
                    <li>
                      <span>개인정보 보호책임자</span></li>
                    <li>
                      <span>성명 : 이정환</span></li>
                    <li>
                    <li>
                      <span>연락처 : 070-7591-8896, cs@payhub.co.kr</span></li>
                  </ul>
                  <p class="terms-description">※ 개인정보 보호 담당부서로 연결됩니다.</p>
                  <ul class="manager-info list-dotted">
                    <li>
                      <span>개인정보 보호 담당부서</span></li>
                    <li>
                      <span>부서명 : CS 부서</span></li>
                    <li>
                      <span>담당자 : 노은선</span></li>
                    <li>
                      <span>연락처 : 070-7591-8896, cs@payhub.co.kr</span></li>
                  </ul>
                  <p class="terms-description">정보주체께서는 주식회사 페이허브의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에
                    관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
                    주식회사 페이허브는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
                </div>
                <div class="terms-wrap">
                  <h5>제11조 정보주체의 권익 침해에 대한 구제방법</h5>
                  <p class="terms-description">아래의 기관은 당사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한
                    도움이 필요하시면 문의하여 주시기 바랍니다.</p>
                  <ol class="link-info">
                    <li>개인정보 침해신고센터 (한국인터넷진흥원 운영)</li>
                    <ul class="manager-info list-dotted">
                      <li>
                        <span>소관업무 : 개인정보 침해사실 신고, 상담 신청</span></li>
                      <li>
                        <span>홈페이지 :  <a
                            class="terms-link"
                            href="https://privacy.kisa.or.kr/main.do"
                            target="_blank">privacy.kisa.or.kr</a></span></li>
                      <li>
                        <span>전화 : (국번없이) 118</span></li>
                      <li>
                        <span>주소 : (05717) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</span></li>
                    </ul>

                    <li>개인정보 분쟁조정위원회 (개인정보보호위원회 운영)</li>
                    <ul class="manager-info list-dotted">
                      <li>
                        <span>소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</span></li>
                      <li>
                        <span>홈페이지 : <a
                            class="terms-link"
                            href="https://www.kopico.go.kr/main/main.do"
                            target="_blank">www.kopico.go.kr</a></span></li>
                      <li>
                        <span>전화 : (국번없이) 1833-6972</span></li>
                      <li>
                        <span>주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층</span></li>
                    </ul>
                    <li>대검찰청 사이버수사과 : (국번없이) 1301
                      <a
                          class="terms-link"
                          href="http://www.spo.go.kr/site/spo/main.do"
                          target="_blank">(www.spo.go.kr)</a>
                    </li>
                    <li>경찰청 사이버안전국 : (국번없이) 182
                      <a
                          class="terms-link"
                          href="https://cyberbureau.police.go.kr/index.do"
                          target="_blank">(cyberbureau.police.go.kr)</a>
                    </li>
                  </ol>
                </div>
                <div class="terms-wrap">
                  <h5>제12조 개인정보 처리방침 고지의 의무</h5>
                  <p class="terms-description">이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행
                    7일 전부터 공지사항을 통하여 고지할 것입니다.</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </main>
</template>

<script>
export default {
  name: "Term2",
  path: '/term/2',
}
</script>

<style scoped>

</style>

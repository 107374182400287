<template>
  <main class="sub-main">
    <!-- ======= section1 function ======= -->
    <section class="section-80 service section1">
      <div class="container">
        <div class="function-tab">
          <ul>
            <li><a @click="$router.push({name:'ServiceQuick'})"><span>퀵비충전</span></a></li>
            <li><a @click="$router.push({name:'ServiceMonthly'})"><span>월세결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceQr'})"><span>QR·카톡·수기결제</span></a></li>
            <li><a @click="$router.push({name:'ServiceShop'})"><span>쇼핑몰 지원</span></a></li>
            <li class="fucus"><a @click="$router.push({name:'ServiceSales'})"><span>매출 알림 서비스</span></a></li>
          </ul>
        </div>
        <div class="function-cont">
          <div class="row">
            <div class="fun-tit">
              <h2><em>전날 매출을 한곳에 확인하는</em><br>매출 알림 서비스</h2>
              <hr>
              <p>매번 각 어플 관리자페이지에서 매출을 확인하던 번거로움!<br>
                배달의민족, 여기요, 쿠팡잇츠, 카드사를 통한 전날 매출을 한곳에서 확인하세요
              </p>
            </div>
            <div class="fun-img">
              <img src="/image/service_sales.png" alt="매출알림서비스">
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section1 -->

    <!-- ======= section2 screen ======= -->
    <section class="section-80 service section2 bg-pink">
      <div class="container">
        <h2 class="sub-tit">매출 알림 서비스 화면</h2>
        <div class="row">
          <div class="screen-box">
            <div class="web_sc">
              <ul class="justify-content-between">
                <li class="col-3">
                  <span class="screen-tit pl-0">01. 전체 매출</span>
                  <div class="img-box">
                    <img src="/image/screen_sales_1.png" alt="전체매출">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">02. 카드 매출</span>
                  <div class="img-box">
                    <img src="/image/screen_sales_2.png" alt="카드매출">
                  </div>
                </li>
                <li class="col-3">
                  <span class="screen-tit pl-0">03. 가맹점 매출</span>
                  <div class="img-box">
                    <img src="/image/screen_sales_3.png" alt="가맹점매출">
                  </div>
                </li>

              </ul>
            </div>
            <!-- Swiper -->
            <div class="swiper sw_sc_1 sw_simple_1">
              <div class="swiper-wrapper">
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">01. 전체 매출</span>
                  <img src="/image/screen_sales_1.png" alt="전체매출">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">02. 카드 매출</span>
                  <img src="/image/screen_sales_2.png" alt="카드매출">
                </div>
                <div class="swiper-slide sw_sc_img">
                  <span class="screen-tit pl-0">03. 가맹점 매출</span>
                  <img src="/image/screen_sales_3.png" alt="가맹점매출">
                </div>
              </div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- End section2 -->

  </main>
</template>

<script>
export default {
  name: "ServiceSales",
  path: '/service/sales',
  mounted() {

    new window.Swiper('.sw_simple_1', {
      loop: false,
      navigation: {
        nextEl: ".sw_simple_1 .swiper-button-next",
        prevEl: ".sw_simple_1 .swiper-button-prev",
      },
    });
  }
}
</script>

<style scoped>

</style>
